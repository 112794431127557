// app/modules/invoices/invoice.actions.ts

import { createAction, props } from '@ngrx/store';
import { v1 as ApiDops } from '@xform/api-dops';

export enum InvoiceActionTypes {
    InvoiceError = '[Invoice] Get Program Error',
    ClearInvoiceError = '[Invoice] Clear Program Error',
    GetInvoice = '[Invoice] Get Invoice',
    GetInvoiceSuccess = '[Invoice] Get Invoice Success',
    GetInvoiceList = '[Invoice] Get Invoice List',
    GetInvoiceListSuccess = '[Invoice] Get Invoice List Success',
}

export const invoiceError = createAction(InvoiceActionTypes.InvoiceError, props<{error: ApiDops.Error}>());
export const clearInvoiceError = createAction(InvoiceActionTypes.ClearInvoiceError);
export const getInvoice = createAction(InvoiceActionTypes.GetInvoice, props<{invoiceId: string}>());
export const getInvoiceSuccess = createAction(InvoiceActionTypes.GetInvoiceSuccess, props<{invoice: ApiDops.InvoiceView}>());
export const getInvoiceList = createAction(InvoiceActionTypes.GetInvoiceList, props<{ lastEvaluatedKey?: string }>());
export const getInvoiceListSuccess = createAction(InvoiceActionTypes.GetInvoiceListSuccess, props<{invoiceList: ApiDops.InvoiceList}>());
