// app/modules/invoices/invoice.reducer.ts

import { Action, createReducer, on } from '@ngrx/store';
import { v1 as ApiDops } from '@xform/api-dops';
import * as invoiceActions from './invoice.actions';

export interface State {
  invoiceId?: string;
  invoicesLoaded: boolean;
  invoice?: ApiDops.InvoiceView;
  invoiceList?: ApiDops.InvoiceList;
  error?: ApiDops.Error;
  loading: boolean;
}

const initialState: State = {
  loading: false,
  invoicesLoaded: false,
};

export const reducers = createReducer(
  initialState,
  on(invoiceActions.invoiceError, (state, { error }) => ({...state, error, loading: false })),
  on(invoiceActions.clearInvoiceError, (state) => ({...state, error: null})),
  on(invoiceActions.getInvoice, (state, { invoiceId }) => ({...state, invoiceId, error: null, loading: true})),
  on(invoiceActions.getInvoiceSuccess, (state, { invoice }) => ({...state, invoice, error: null, loading: false})),
  on(invoiceActions.getInvoiceList, (state) => ({...state, error: null, loading: true})),
  on(invoiceActions.getInvoiceListSuccess, (state, { invoiceList }) => ({...state, invoiceList, error: null, invoicesLoaded: true, loading: false})),
);
