// app/modules/notification/web-hooks/webhook.actions.ts

import { createAction, props } from '@ngrx/store';
import { v1 as ApiDops } from '@xform/api-dops';

export enum WebhookActionTypes {
  WebhookError = '[Webhook] Get Program Error',
  ClearWebhookError = '[Webhook] Clear Program Error',
  GetWebhook = '[Webhook] Get Webhook',
  GetWebhookSuccess = '[Webhook] Get Webhook Success',
  ModifyWebhook = '[Webhook] Modify Webhook',
  ModifyWebhookSuccess = '[Webhook] Modify Webhook Success',
}

export const webhookError = createAction(WebhookActionTypes.WebhookError, props<{ error: ApiDops.Error }>());
export const clearWebhookError = createAction(WebhookActionTypes.ClearWebhookError);
export const getWebhook = createAction(WebhookActionTypes.GetWebhook);
export const getWebhookSuccess = createAction(WebhookActionTypes.GetWebhookSuccess, props<{ webhook: ApiDops.WebhookView }>());
export const modifyWebhook = createAction(WebhookActionTypes.ModifyWebhook, props<{ modifyWebhookEventId: string, modifyWebhook: ApiDops.ModifyWebhookEvent }>());
export const modifyWebhookSuccess = createAction(WebhookActionTypes.ModifyWebhookSuccess);
