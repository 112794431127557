// app/modules/shared/utils/utils.ts

import { Injectable } from '@angular/core';
import { Params, RouterStateSnapshot } from '@angular/router';
import { createFeatureSelector, Store } from '@ngrx/store';
import { RouterReducerState } from '@ngrx/router-store/src/reducer';
import { RouterStateSerializer } from '@ngrx/router-store/src/serializers/base';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { TypedAction } from '@ngrx/store/src/models';
import { environment } from '../../../../environments/environment';
import { ServiceId } from '../services/search/search';

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
}

export const getRouterState = createFeatureSelector<RouterReducerState<RouterStateUrl>>('router');

@Injectable()
export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateUrl> {
  public serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const { url, root: { queryParams } } = routerState;
    const { params } = route;

    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    return { url, params, queryParams };
  }
}

export const daysPriorToDate = (numberOfDays: number, date = new Date()): Date => {
  date.setDate(date.getDate() - numberOfDays);
  return date;
};

// eslint-disable-next-line no-magic-numbers
export const getEndOfDayAndTime = (date: string | Date): string => new Date(new Date(date).setUTCHours(23, 59, 59, 999)).toISOString();

export const getStartOfDayAndTime = (date: string | Date): string => new Date(new Date(date).setUTCHours(0, 0, 0, 0)).toISOString();

export const errorSubToast = (toastr: ToastrService, store: Store, message: string, clear: TypedAction<any>) => (err: any): void => {
  if (err) {
    toastr.error(`${message} ${(typeof err.message === 'object' ? ((err.message)?.code || 'Invalid Request') : err.message) as string}`);
    store.dispatch(clear);
  }
};

export const daysPriorToToday = (numberOfDays: number): Date => {
  const date = new Date();
  date.setDate(date.getDate() - numberOfDays);
  return date;
};

export const daysPriorOfDate = (daysPrior: number, date: Date): Date => {
  const dateTime = new Date(date);
  dateTime.setDate(date.getDate() - daysPrior);
  return dateTime;
};

export const oneWeek = 7;

export function getEnvBaseUrl(route: string): string {
  switch (Object.values(ServiceId).find(serviceId => route.includes(serviceId))) {
  case ServiceId.Batch:
    return environment.API_BATCH_SERVICE_URL;
  case ServiceId.Card:
    return environment.API_CARD_SERVICE_URL;
  case ServiceId.Fops:
    return environment.API_FOPS_SERVICE_URL;
  case ServiceId.Ledger:
    return environment.API_LEDGER_SERVICE_URL;
  case ServiceId.Organization:
    return environment.API_ORGANIZATION_SERVICE_URL;
  case ServiceId.Payment:
    return environment.API_PAYMENT_SERVICE_URL;
  case ServiceId.Program:
    return environment.API_PROGRAM_SERVICE_URL;
  case ServiceId.Report:
    return environment.API_REPORT_SERVICE_URL;
  case ServiceId.Simulator:
    return environment.API_SIMULATOR_SERVICE_URL;
  default:
    return environment.API_DOPS_SERVICE_URL;
  }
}

export function getCustomBaseUrl(route: string, env: string): string {
  const calledService = Object.values(ServiceId).find(serviceId => route.includes(serviceId)) ?? ServiceId.Dops;
  return `https://${calledService}${env}.${environment.HOSTED_ZONE}`;
}

export const copyToClipboard = (stringToCopy: string, toastr: ToastrService): Promise<ActiveToast<any>> => navigator?.clipboard?.writeText(stringToCopy).then(() => toastr.success('Copied'));

export const getFormatKeyTooltip = (key: string): string => key.includes('\\n')
  ? 'Format text as a block'
  : 'Format text as a string for use in an endpoint';

export const formatKey = (key: string): string => key.includes('\\n')
  // eslint-disable-next-line prefer-named-capture-group
  ? key.replace(/(\\r)?\\n/gu, '\n').trim()
  : JSON.stringify(key.trim()).replace(/"/gu, '');
