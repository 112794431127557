// app/modules/developers/developer.actions.ts

import { createAction, props } from '@ngrx/store';
import { v1 as ApiDops } from '@xform/api-dops';

export enum DeveloperActionTypes {
    DeveloperError = '[Developer] Get Developer Error',
    ClearDeveloperError = '[Developer] Clear Developer Error',
    CancelDeveloper = '[Developer] Cancel Developer',
    CancelDeveloperSuccess = '[Developer] Cancel Developer Success',
    ReactivateDeveloper = '[Developer] Reactivate Developer',
    ReactivateDeveloperSuccess = '[Developer] Reactivate Developer Success',
    GetDeveloper = '[Developer] Get Developer',
    GetDeveloperSuccess = '[Developer] Get Developer Success',
    GetDeveloperList = '[Developer] Get Developer List',
    GetDeveloperListSuccess = '[Developer] Get Developer List Success',
    ModifyDeveloper = '[Developer] Modify Developer',
    ModifyDeveloperSuccess = '[Developer] Modify Developer Success',
    NewDeveloper = '[Developer] New Developer',
    NewDeveloperSuccess = '[Developer] New Developer Success',
}

export const developerError = createAction(DeveloperActionTypes.DeveloperError, props<{error: ApiDops.Error}>());
export const clearDeveloperError = createAction(DeveloperActionTypes.ClearDeveloperError);
export const cancelDeveloper = createAction(DeveloperActionTypes.CancelDeveloper, props<{cancelDeveloperEventId: string, cancelDeveloper: ApiDops.CancelDeveloperEvent, lastEvaluatedKey?: string}>());
export const cancelDeveloperSuccess = createAction(DeveloperActionTypes.CancelDeveloperSuccess);
export const reactivateDeveloper = createAction(DeveloperActionTypes.ReactivateDeveloper, props<{reactivateDeveloperEventId: string, reactivateDeveloper: ApiDops.ReactivateDeveloperEvent, lastEvaluatedKey?: string}>());
export const reactivateDeveloperSuccess = createAction(DeveloperActionTypes.ReactivateDeveloperSuccess);
export const getDeveloper = createAction(DeveloperActionTypes.GetDeveloper, props<{developerId: string}>());
export const getDeveloperSuccess = createAction(DeveloperActionTypes.GetDeveloperSuccess, props<{developer: ApiDops.DeveloperView}>());
export const getDeveloperList = createAction(DeveloperActionTypes.GetDeveloperList, props<{ lastEvaluatedKey?: string }>());
export const getDeveloperListSuccess = createAction(DeveloperActionTypes.GetDeveloperListSuccess, props<{developerList: ApiDops.DeveloperList}>());
export const modifyDeveloper = createAction(DeveloperActionTypes.ModifyDeveloper, props<{modifyDeveloperEventId: string, modifyDeveloper: ApiDops.ModifyDeveloperEvent, lastEvaluatedKey?: string}>());
export const modifyDeveloperSuccess = createAction(DeveloperActionTypes.ModifyDeveloperSuccess);
export const newDeveloper = createAction(DeveloperActionTypes.NewDeveloper, props<{newDeveloperEventId: string, newDeveloper: ApiDops.NewDeveloperEvent, lastEvaluatedKey?: string}>());
export const newDeveloperSuccess = createAction(DeveloperActionTypes.NewDeveloperSuccess);
