// environments/environment.dev.ts

export const environment = {
  production: false,

  authIdentityPoolId: 'us-east-1:698cf4f6-facd-461b-8ffd-bdb1e511f899',
  authRegion: 'us-east-1',
  authUserPoolId: 'us-east-1_WrRUCJ08x',
  authUserPoolWebClientId: '7qvi4b677rlovv56c17a7j1psa',
  authenticationFlowType: 'USER_SRP_AUTH',

  oauthDomain: 'xformative-aviato-dev.auth.us-east-1.amazoncognito.com',
  oauthScope: ['openid', 'aws.cognito.signin.user.admin'],
  oauthRedirectSignIn: 'https://dops.xform-dev.xformative.cloud/login',
  oauthRedirectSignOut: 'https://dops.xform-dev.xformative.cloud',

  oauthResponseType: 'token',

  API_DOPS_SERVICE_URL: 'https://api-dops.xform-dev.xformative.cloud',
  API_CARD_SERVICE_URL: 'https://api-card.xform-dev.xformative.cloud',
  API_LEDGER_SERVICE_URL: 'https://api-ledger.xform-dev.xformative.cloud',
  API_ORGANIZATION_SERVICE_URL: 'https://api-organization.xform-dev.xformative.cloud',
  API_PAYMENT_SERVICE_URL: 'https://api-payment.xform-dev.xformative.cloud',
  API_PROGRAM_SERVICE_URL: 'https://api-program.xform-dev.xformative.cloud',
  API_SIMULATOR_SERVICE_URL: 'https://api-simulator.xform-dev.xformative.cloud',
  API_REPORT_SERVICE_URL: 'https://api-report.xform-dev.xformative.cloud',
  API_FOPS_SERVICE_URL: 'https://api-fops.xform-dev.xformative.cloud',
  API_BATCH_SERVICE_URL: 'https://api-batch.xform-dev.xformative.cloud',
  PROGRAM_SERVICE_URL: 'https://program.xform-dev.xformative.cloud',

  IS_LOCAL: false,
  URL_SPECIAL_HANDLING: false,
  HOSTED_ZONE: 'xformative.cloud',
  MAIN_ENV: 'xform-dev',
}
