// app/modules/app-shell/container/container.component.ts

import { Component } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
})
export class ContainerComponent {

  isScreenSmall: boolean;

  constructor(
    public mediaObserver: MediaObserver,
  ) {
    mediaObserver.asObservable().subscribe(changes => {
      this.isScreenSmall = !['lg', 'xl'].includes(changes[0].mqAlias);
    });
  }
}