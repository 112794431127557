// app/modules/app-shell/sidenav/sidenav.component.ts
import { Component, Input, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Store } from '@ngrx/store';
import packageInfo from '../../../../../package.json';
import { selectEnvironment } from '../../../core/auth/auth.selectors';
import { RoleBasedPermission } from '../../../shared/directives/access.map';
import { Environment } from '../../../shared/ui-models/environment';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent {

  // expose sidenav to allow header to toggle
  @ViewChild('sidenav') matSideNav: MatSidenav;

  @Input() isScreenSmall: boolean;

  environment = Environment;

  appVersion: string = packageInfo.version;

  permissions = RoleBasedPermission;

  selected$ = this.store.select(selectEnvironment);

  constructor(
    private store: Store,
  ) {
  }

}
