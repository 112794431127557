// app/modules/core-services/payments/state/payment.reducer.ts

import { createReducer, on } from '@ngrx/store';
import { v1 as ApiDops } from '@xform/api-dops';
import * as paymentActions from './payment.actions';

export interface State {
  paymentsLoadedWithCriteria: boolean;
  at?: string;
  paymentListWithCriteria?: ApiDops.SearchResults;
  error?: ApiDops.Error;
  loadingWithCriteria: boolean;
  searchTerm?: string;
  searchEndDateTime?: string;
}

const initialState: State = {
  loadingWithCriteria: false,
  paymentsLoadedWithCriteria: false,
};

export const reducers = createReducer(
  initialState,
  on(paymentActions.paymentError, (state, { error }) => ({...state, error, loadingWithCriteria: false, paymentsLoadedWithCriteria: false })),
  on(paymentActions.clearPaymentError, (state) => ({...state, error: null})),
  on(paymentActions.getPaymentListWithCriteria, (state, { searchTerm, searchEndDateTime }) => ({...state, paymentListWithCriteria: null, error: null, loadingWithCriteria: true, searchTerm, searchEndDateTime})),
  on(paymentActions.getPaymentListWithCriteriaSuccess, (state, { paymentListWithCriteria }) => ({...state, paymentListWithCriteria, error: null, paymentsLoadedWithCriteria: true, loadingWithCriteria: false})),
);
