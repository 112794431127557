// app/modules/core-services/organization/state/organization.reducer.ts

import { createReducer, on } from '@ngrx/store';
import { v1 as ApiDops } from '@xform/api-dops';
import * as organizationActions from './organization.actions';

export interface State {
  organizationsLoadedWithCriteria: boolean;
  at?: string;
  organizationListWithCriteria?: ApiDops.SearchResults;
  error?: ApiDops.Error;
  loadingWithCriteria: boolean;
  searchTerm?: string;
  searchEndDateTime?: string;
}

const initialState: State = {
  loadingWithCriteria: false,
  organizationsLoadedWithCriteria: false,
};

export const reducers = createReducer(
  initialState,
  on(organizationActions.organizationError, (state, { error }) => ({ ...state, error, loadingWithCriteria: false, organizationsLoadedWithCriteria: false })),
  on(organizationActions.clearOrganizationError, (state) => ({ ...state, error: null })),
  on(organizationActions.getOrganizationListWithCriteria, (state, { searchTerm, searchEndDateTime }) => ({ ...state, organizationListWithCriteria: null, error: null, loadingWithCriteria: true, searchTerm, searchEndDateTime })),
  on(organizationActions.getOrganizationListWithCriteriaSuccess, (state, { organizationListWithCriteria }) => ({ ...state, organizationListWithCriteria, error: null, organizationsLoadedWithCriteria: true, loadingWithCriteria: false })),
);
