// app/core/auth/auth.selectors.ts

import { createFeatureSelector, createSelector, MemoizedSelector, Selector } from '@ngrx/store';
import { v1 as ApiDops } from '@xform/api-dops';
import { v1 as ApiFops } from '@xform/api-fops';
import { DeveloperNotification, DeveloperProfile, DeveloperView } from '@xform/api-dops/dist/api/v1';
import { MFASetupResponse } from '../../modules/shared/services/cognito-service/cognito-auth.service';
import { State } from './auth.reducer';

const whiteListUserIds = [
  '4ecd0c2d-4466-4154-9b11-7e0c5841a29e',
];

export const selectAuthState: Selector<State, State> = createFeatureSelector<State>('auth');

export const selectUser: MemoizedSelector<State, DeveloperView | undefined> = createSelector(
  selectAuthState,
  (state: State) => state && state.user);

export const selectUserLoadingState: Selector<State, boolean | undefined> = createSelector(
  selectAuthState,
  (state: State) => state && state.loadingUser);

export const selectUserBureauId: Selector<State, string | undefined> = createSelector(
  selectAuthState,
  (state: State) => state && state.userBureauId);

export const selectUserId: MemoizedSelector<State, string | undefined> = createSelector(
  selectAuthState,
  (state: State) => state && state.userId);

export const selectUserError: Selector<State, ApiDops.Error | undefined> = createSelector(
  selectAuthState,
  (state: State) => state && state.generalError);

export const selectAuthError: Selector<State, ApiDops.Error | undefined> = createSelector(
  selectAuthState,
  (state: State) => state && state.error);

export const selectUserProfile: MemoizedSelector<State, DeveloperProfile | undefined> = createSelector(
  selectUser,
  (developerView: DeveloperView) => developerView && developerView.profile);

export const selectUserNotifications: MemoizedSelector<State, DeveloperNotification[] | undefined> = createSelector(
  selectUserProfile,
  (developer: DeveloperProfile) => {
    if (developer?.settings?.notifications) {
      return developer.settings.notifications;
    }
    return null;
  });

export const selectAccessClaim: Selector<State, string | undefined> = createSelector(
  selectAuthState,
  (state: State) => state && state.selectedAccessClaim,
);

export const selectAuthenticationStatus: Selector<State, boolean | undefined> = createSelector(
  selectAuthState,
  (state: State) => state && state.isAuthenticated,
);

export const selectMFAStatus: Selector<State, boolean | undefined> = createSelector(
  selectAuthState,
  (state: State) => (state && state.isMFASetup) || whiteListUserIds.includes(state.userId),
);

export const selectBureauLoadingState: Selector<State, boolean> = createSelector(
  selectAuthState,
  (state: State) => state && state.bureauLoading);

export const selectBureau: MemoizedSelector<State, ApiDops.BureauView | undefined> = createSelector(
  selectAuthState,
  (state: State) => state && state.bureau);

export const selectChallengeName: MemoizedSelector<State, string | undefined> = createSelector(
  selectAuthState,
  (state: State) => state && state.challengeName);

export const selectMFASetupResponse: MemoizedSelector<State, MFASetupResponse | undefined> = createSelector(
  selectAuthState,
  (state: State) => state && state.mfaSetupResponse);

// eslint-disable-next-line no-implicit-coercion
export const selectLoggedIn = createSelector(selectUser, (user) => !!user);

export const selectLoggingIn = createSelector(
  selectAuthState,
  (state: State) => state && state.loggingIn,
);

export const selectSignInLoadingState: MemoizedSelector<State, boolean | undefined> = createSelector(
  selectAuthState,
  (state: State) => state && state.signInLoading,
);

export const selectMFALoadingState: MemoizedSelector<State, boolean | undefined> = createSelector(
  selectAuthState,
  (state: State) => state && state.mfaAuthLoading,
);

export const selectEnvironment: MemoizedSelector<State, string | undefined> = createSelector(
  selectAuthState,
  (state: State) => state && state.selectedEnvironment,
);

export const previousEnvironment: MemoizedSelector<State, string | undefined> = createSelector(
  selectAuthState,
  (state: State) => state && state.previousEnvironment,
);

export const selectJWT: MemoizedSelector<State, string | undefined> = createSelector(
  selectAuthState,
  (state: State) => state && state.jwt,
);

// ** note: will only be populated for ops-dev role
export const selectBureauList: MemoizedSelector<State, ApiFops.BureauList | undefined> = createSelector(
  selectAuthState,
  (state: State) => state && state.bureauList,
);
