// app/modules/core-services/ledger/state/ledger.actions.ts

/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-classes-per-file */

import { createAction, props } from '@ngrx/store';
import { v1 as ApiDops } from '@xform/api-dops';
import { SearchParams } from '../../../shared/services/search/search';

export enum LedgerActionTypes {
    ClearState = '[Ledger] Clear State',
    ClearLedgerListWithCriteria = '[Ledger] Clear Ledger List With Criteria',
    LedgerError = '[Ledger] Get Ledger Error',
    ClearLedgerError = '[Ledger] Clear Ledger Error',
    GetLedgerListWithCriteria = '[Ledger] Get Ledger List With Criteria',
    GetLedgerListWithCriteriaSuccess = '[Ledger] Get Ledger List With Criteria Success',
}

export const clearState = createAction(LedgerActionTypes.ClearState);
export const clearLedgerListWithCriteria = createAction(LedgerActionTypes.ClearLedgerListWithCriteria);
export const ledgerError = createAction(LedgerActionTypes.LedgerError, props<{error: ApiDops.Error}>());
export const clearLedgerError = createAction(LedgerActionTypes.ClearLedgerError);
export const getLedgerListWithCriteria = createAction(LedgerActionTypes.GetLedgerListWithCriteria, props<Partial<SearchParams>>());
export const getLedgerListWithCriteriaSuccess = createAction(LedgerActionTypes.GetLedgerListWithCriteriaSuccess, props<{ledgerListWithCriteria: ApiDops.SearchResults}>());
