// app/modules/core-services/ledger/state/ledger.reducer.ts

import { Action, createReducer, on } from '@ngrx/store';
import { v1 as ApiDops } from '@xform/api-dops';
import * as ledgerActions from './ledger.actions';

export interface State {
  ledgersLoadedWithCriteria: boolean;
  at?: string;
  ledgerListWithCriteria?: ApiDops.SearchResults;
  error?: ApiDops.Error;
  loadingWithCriteria: boolean;
  searchTerm?: string;
  searchEndDateTime?: string;
}

const initialState: State = {
  loadingWithCriteria: false,
  ledgersLoadedWithCriteria: false,
};

export const reducers = createReducer(
  initialState,
  on(ledgerActions.ledgerError, (state, { error }) => ({...state, error, loadingWithCriteria: false, ledgersLoadedWithCriteria: false })),
  on(ledgerActions.clearLedgerError, (state) => ({...state, error: null})),
  on(ledgerActions.getLedgerListWithCriteria, (state, { searchTerm, searchEndDateTime }) => ({...state, ledgerListWithCriteria: null, error: null, loadingWithCriteria: true, searchTerm, searchEndDateTime})),
  on(ledgerActions.getLedgerListWithCriteriaSuccess, (state, { ledgerListWithCriteria }) => ({...state, ledgerListWithCriteria, error: null, ledgersLoadedWithCriteria: true, loadingWithCriteria: false})),
);
