<mat-toolbar color="primary" class="header-toolbar">
    <button *ngIf="isScreenSmall" class="menu-icon" (click)="onToggleSidenav()">
        <hero-icon class="icon" name="menu"></hero-icon>
    </button>

    <div>
        <a routerLink="/home">
            <img class="logo-img" [ngStyle.xs]="'width.px: 0'" src="../../../../assets/xf-logo.svg" alt="Xformative">
        </a>
    </div>

    <!-- div to fill empty space -->
    <div fxFlex></div>

    <ng-container *ngIf="selected$ | async as instances">
        <div class="div-drop-down">
            <mat-select id="environment" [value]="selectedEnv" [compareWith]="compareEnviroments"  (selectionChange)="environmentChange($event.value)"
                class="select-environment">
                <mat-option class="option-text" *ngFor="let env of instances" [value]="env.value">
                    {{env.displayLabel}}
                </mat-option>
            </mat-select>
        </div>
      </ng-container>

    <button [matMenuTriggerFor]="menu" class='header-menu-button profile-menu'>
        <hero-icon class="icon" name="userSolid"></hero-icon>
    </button>

    <!-- my-menu styling comes from styles.scss -->

    <mat-menu #menu="matMenu" class="my-menu">
        <div>
            <h4 *ngIf="userProfile$ | async as userProfile" class="display-name">{{userProfile.firstName + ' ' + userProfile.lastName}}</h4>
            <div class="block-8"></div>
            <div div fxLayout="column" fxLayoutAlign="space-around center">
                <span *ngIf="userProfile$ | async as userProfile" (click)="routeToProfile()" class="action">Profile</span>
                <span (click)="signOut()" class="action">Sign Out</span>
            </div>
        </div>

    </mat-menu>
</mat-toolbar>
