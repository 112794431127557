// app/core/auth/auth.guard.ts

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { loginRedirect, mfaRedirect } from './auth.actions';
import { AuthEffects } from './auth.effects';
import { selectAuthenticationStatus, selectLoggedIn, selectMFAStatus, selectUserId } from './auth.selectors';

/**
 * Prevent access to routes if access-token is not present.
 *
 * @export
 * @class AuthGuard
 * @implements {CanActivate}
 */
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store,
  ) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return combineLatest([
      this.store.select(selectAuthenticationStatus),
      this.store.select(selectUserId),
      this.store.select(selectMFAStatus),
    ]).pipe(
      map(([isAuthenticated, hasUserId, mfaStatus]) => {
        if (!isAuthenticated || !hasUserId) {
          this.store.dispatch(loginRedirect());
          return false;
        }
        if (!mfaStatus) {
          this.store.dispatch(mfaRedirect());
          return false;
        }
        return true;
      }),
      take(1),
    );
  }
}