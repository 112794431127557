// app/modules/core-services/organization/state/organization.actions.ts

/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-classes-per-file */

import { createAction, props } from '@ngrx/store';
import { v1 as ApiDops } from '@xform/api-dops';
import { SearchParams } from '../../../shared/services/search/search';

export enum OrganizationActionTypes {
    ClearState = '[Organization] Clear State',
    ClearOrganizationListWithCriteria = '[Organization] Clear Organization List With Criteria',
    OrganizationError = '[Organization] Get Organization Error',
    ClearOrganizationError = '[Organization] Clear Organization Error',
    GetOrganizationListWithCriteria = '[Organization] Get Organization List With Criteria',
    GetOrganizationListWithCriteriaSuccess = '[Organization] Get Organization List With Criteria Success',
}

export const clearState = createAction(OrganizationActionTypes.ClearState);
export const clearOrganizationListWithCriteria = createAction(OrganizationActionTypes.ClearOrganizationListWithCriteria);
export const organizationError = createAction(OrganizationActionTypes.OrganizationError, props<{error: ApiDops.Error}>());
export const clearOrganizationError = createAction(OrganizationActionTypes.ClearOrganizationError);
export const getOrganizationListWithCriteria = createAction(OrganizationActionTypes.GetOrganizationListWithCriteria, props<Partial<SearchParams>>());
export const getOrganizationListWithCriteriaSuccess = createAction(OrganizationActionTypes.GetOrganizationListWithCriteriaSuccess, props<{organizationListWithCriteria: ApiDops.SearchResults}>());
