// app/modules/reconciliation/reconciliation.actions.ts

import { createAction, props } from '@ngrx/store';
import { v1 as ApiDops } from '@xform/api-dops';
import { v1 as ApiReportV1 } from '@xform/api-report';
import { PaymentSourceType, ReconciliationReportType, SIGISReportType } from './reconciliation.service';

export enum ReconciliationActionTypes {
    GetFileList = '[Reconciliation] Get File List',
    GetFileListSuccess = '[Reconciliation] Get File List Success',
    GetGenericSignedUrl = '[Reconciliation] Get Generic Signed Url',
    GetSignedUrlSuccess = '[Reconciliation] Get Signed Url Success',
    GetPaymentSignedUrl = '[Reconciliation] Get Payment Signed Url',
    ClearFileList = '[Reconciliation] Clear File List',
    FileListError = '[Reconciliation] File List Error',
    ClearFileListError = '[Reconciliation] Clear File List Error',
    GetPaymentFileList = '[Reconciliation] Get Payment File List',
    GetPaymentFileListSuccess = '[Reconciliation] Get Payment File List Success',
    GetMerchantListFileList = '[Reconciliation] Get Merchant List File List',
    GetMerchantListSignedUrl = '[Reconciliation] Get Merchant List Signed Url',
    ClearSignedUrl = '[Reconciliation] Clear Signed Url',
    SignedUrlError = '[Reconciliation] Signed Url Error',
    ClearSignedUrlError = '[Reconciliation] Clear Signed Url Error',
};

export const getFileList = createAction(ReconciliationActionTypes.GetFileList, props<{fileType: ReconciliationReportType, fromDate: string, toDate: string}>());
export const getFileListSuccess = createAction(ReconciliationActionTypes.GetFileListSuccess, props<{fileList: any}>());
export const getPaymentFileList = createAction(ReconciliationActionTypes.GetPaymentFileList, props<{paymentSourceType: PaymentSourceType, fromDate: string, toDate: string}>());
export const getGenericSignedUrl = createAction(ReconciliationActionTypes.GetGenericSignedUrl, props<{s3Location: string}>());
export const getSignedUrlSuccess = createAction(ReconciliationActionTypes.GetSignedUrlSuccess, props<{signedUrl: string}>());
export const getPaymentSignedUrl = createAction(ReconciliationActionTypes.GetPaymentSignedUrl, props<{s3Location: string}>());
export const getMerchantListFileList = createAction(ReconciliationActionTypes.GetMerchantListFileList, props<{fileSourceType: SIGISReportType, reportDate: string}>());
export const getMerchantListSignedUrl = createAction(ReconciliationActionTypes.GetMerchantListSignedUrl, props<{s3Location: string}>());
export const clearFileList = createAction(ReconciliationActionTypes.ClearFileList);
export const fileListError = createAction(ReconciliationActionTypes.FileListError, props<{error: ApiDops.Error}>());
export const clearFileListError = createAction(ReconciliationActionTypes.ClearFileListError);
export const clearSignedUrl = createAction(ReconciliationActionTypes.ClearSignedUrl);
export const clearSignedUrlError = createAction(ReconciliationActionTypes.ClearSignedUrlError);
export const signedUrlError = createAction(ReconciliationActionTypes.SignedUrlError, props<{error: ApiReportV1.Error}>());
