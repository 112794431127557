// app/modules/core-services/programs/state/program.reducer.ts

import { createReducer, on } from '@ngrx/store';
import { SearchResults } from '@xform/api-dops/dist/api/v1';
import { v1 as ProgramApi } from '@xform/api-program';
import * as programActions from './program.actions';

export interface State {
  programId?: string;
  programsLoaded: boolean;
  programsLoadedWithCriteria: boolean;
  newProgramEventId?: string;
  parentProgram?: ProgramApi.ProgramView;
  newProgramEvent?: ProgramApi.NewProgramEvent;
  setCardFulfillmentEventId?: string;
  setCardFulfillmentEvent?: ProgramApi.SetCardFulfillmentEvent;
  setProcessorRestrictCountriesEventId?: string;
  setProcessorRestrictCountriesEvent?: ProgramApi.SetProcessorRestrictCountriesEvent;
  setProcessorRestrictMerchantIdsEventId?: string;
  setProcessorRestrictMerchantIdsEvent?: ProgramApi.SetProcessorRestrictMerchantIdsEvent;
  setProcessorRestrictMerchantTypesEventId?: string;
  setProcessorRestrictMerchantTypesEvent?: ProgramApi.SetProcessorRestrictMerchantTypesEvent;
  setProcessorLimitsEventId?: string;
  setProcessorLimitsEvent?: ProgramApi.SetProcessorLimitsEvent;
  setProcessorTolerancesEventId?: string;
  setProcessorTolerancesEvent?: ProgramApi.SetProcessorTolerancesEvent;
  setPriorityDepletionOrderEventId?: string;
  setPriorityDepletionOrderEvent?: ProgramApi.SetPriorityDepletionOrderEvent;
  newOfferingEventId?: string;
  newOfferingEvent?: ProgramApi.NewOfferingEvent;
  cancelOfferingEventId?: string;
  cancelOfferingEvent?: ProgramApi.CancelOfferingEvent;
  merchantOverrideList?: ProgramApi.OverrideList;
  modifyOfferingEventId?: string;
  modifyOfferingEvent?: ProgramApi.ModifyOfferingEvent;
  modifyProgramEventId?: string;
  modifyProgramEvent?: ProgramApi.ModifyProgramEvent;
  setOfferingManualAuthEligibilityId?: string;
  setOfferingManualAuthEligibilityEvent?: ProgramApi.SetOfferingManualAuthEligibilityEvent;
  setOfferingCardAuthEligibilityId?: string;
  setOfferingCardAuthEligibilityEvent?: ProgramApi.SetOfferingCardAuthEligibilityEvent;
  setOfferingRolloverEventId?: string;
  setOfferingRolloverEvent?: ProgramApi.SetOfferingRolloverEvent;
  removeOfferingRolloverEventId?: string;
  removeOfferingRolloverEvent?: ProgramApi.RemoveOfferingRolloverEvent;
  setOfferingGracePeriodEventId?: string;
  setOfferingGracePeriodEvent?: ProgramApi.SetOfferingGracePeriodEvent;
  at?: string;
  program?: ProgramApi.ProgramView;
  lookupProgram?: ProgramApi.ProgramView;
  lookupProgramError?: ProgramApi.Error;
  programLookupId?: string;
  programList?: ProgramApi.ProgramList;
  programListLastEvaluatedKey?: string;
  programListWithCriteria?: SearchResults;
  error?: ProgramApi.Error;
  loading: boolean;
  loadingWithCriteria: boolean;
  loadingOverrides: boolean;
}

const initialState: State = {
  loading: false,
  loadingWithCriteria: false,
  loadingOverrides: false,
  programsLoaded: false,
  programsLoadedWithCriteria: false,
};

export const reducers = createReducer(
  initialState,
  on(programActions.newProgram, (state, { newProgramEventId, newProgramEvent }) => ({
    ...state,
    newProgramEventId,
    newProgramEvent,
    error: null,
    loading: true,
  })),
  on(programActions.newProgramSuccess, (state) => ({
    ...state,
    newProgramEventId: null,
    newProgramEvent: null,
    error: null,
    loading: false,
  })),
  on(programActions.getMerchantOverrideListSuccess, (state, { merchantOverrideList }) => ({
    ...state,
    merchantOverrideList,
    loadingOverrides: false,
  })),
  on(programActions.getMerchantOverrideList, (state, {  programId, includesAncestors }) => ({
    ...state,
    loadingOverrides: true,
    programId,
    includesAncestors,
  })),
  on(programActions.updateCardFulfillment, (state, { setCardFulfillmentEventId, setCardFulfillmentEvent }) => ({
    ...state,
    setCardFulfillmentEventId,
    setCardFulfillmentEvent,
    loading: true,
    error: null,
  })),
  on(programActions.updateCardFulfillmentSuccess, (state) => ({
    ...state,
    setCardFulfillmentEventId: null,
    setCardFulfillmentEvent: null,
    loading: false,
    error: null,
  })),
  on(programActions.updateRestrictionCountries, (state, {
    setProcessorRestrictCountriesEventId,
    setProcessorRestrictCountriesEvent,
  }) => ({
    ...state,
    setProcessorRestrictCountriesEventId,
    setProcessorRestrictCountriesEvent,
    loading: true,
    error: null,
  })),
  on(programActions.updateRestrictionCountriesSuccess, (state) => ({
    ...state,
    setProcessorRestrictCountriesEventId: null,
    setProcessorRestrictCountriesEvent: null,
    loading: false,
    error: null,
  })),
  on(programActions.updateMerchantRestrictionsIds, (state, {
    setProcessorRestrictMerchantIdsEventId,
    setProcessorRestrictMerchantIdsEvent,
  }) => ({
    ...state,
    setProcessorRestrictMerchantIdsEventId,
    setProcessorRestrictMerchantIdsEvent,
    loading: true,
    error: null,
  })),
  on(programActions.updateMerchantRestrictionsIdsSuccess, (state) => ({
    ...state,
    setProcessorRestrictMerchantIdsEventId: null,
    setProcessorRestrictMerchantIdsEvent: null,
    loading: false,
    error: null,
  })),
  on(programActions.updateMerchantRestrictionTypes, (state, {
    setProcessorRestrictMerchantTypesEventId,
    setProcessorRestrictMerchantTypesEvent,
  }) => ({
    ...state,
    setProcessorRestrictMerchantTypesEventId,
    setProcessorRestrictMerchantTypesEvent,
    loading: true,
    error: null,
  })),
  on(programActions.updateMerchantRestrictionTypesSuccess, (state) => ({
    ...state,
    setProcessorRestrictMerchantTypesEventId: null,
    setProcessorRestrictMerchantTypesEvent: null,
    loading: false,
    error: null,
  })),
  on(programActions.updateProcessorLimits, (state, { setProcessorLimitsEventId, setProcessorLimitsEvent }) => ({
    ...state,
    setProcessorLimitsEventId,
    setProcessorLimitsEvent,
    loading: true,
    error: null,
  })),
  on(programActions.updateProcessorLimitsSuccess, (state) => ({
    ...state,
    setProcessorLimitsEventId: null,
    setProcessorLimitsEvent: null,
    loading: false,
    error: null,
  })),
  on(programActions.updateProcessorTolerances, (state, {
    setProcessorTolerancesEventId,
    setProcessorTolerancesEvent,
  }) => ({
    ...state,
    setProcessorTolerancesEventId,
    setProcessorTolerancesEvent,
    loading: true,
    error: null,
  })),
  on(programActions.updateProcessorTolerancesSuccess, (state) => ({
    ...state,
    setProcessorTolerancesEventId: null,
    setProcessorTolerancesEvent: null,
    loading: false,
    error: null,
  })),
  on(programActions.updateDepletionOrder, (state, {
    setPriorityDepletionOrderEventId,
    setPriorityDepletionOrderEvent,
  }) => ({
    ...state,
    setPriorityDepletionOrderEventId,
    setPriorityDepletionOrderEvent,
    loading: true,
    error: null,
  })),
  on(programActions.updateDepletionOrderSuccess, (state) => ({
    ...state,
    setPriorityDepletionOrderEventId: null,
    setProcessorTolerancesEvent: null,
    loading: false,
    error: null,
  })),
  on(programActions.newOffering, (state, { newOfferingEventId, newOfferingEvent }) => ({
    ...state,
    newOfferingEventId,
    newOfferingEvent,
    loading: true,
    error: null,
  })),
  on(programActions.newOfferingSuccess, (state) => ({
    ...state,
    newOfferingEventId: null,
    newOfferingEvent: null,
    loading: false,
    error: null,
  })),
  on(programActions.cancelOffering, (state, { cancelOfferingEventId, cancelOfferingEvent }) => ({
    ...state,
    cancelOfferingEventId,
    cancelOfferingEvent,
    loading: true,
    error: null,
  })),
  on(programActions.cancelOfferingSuccess, (state) => ({
    ...state,
    cancelOfferingEventId: null,
    cancelOfferingEvent: null,
    loading: false,
    error: null,
  })),
  on(programActions.modifyOffering, (state, { modifyOfferingEventId, modifyOfferingEvent }) => ({
    ...state,
    modifyOfferingEventId,
    modifyOfferingEvent,
    loading: true,
    error: null,
  })),
  on(programActions.modifyOfferingSuccess, (state) => ({
    ...state,
    modifyOfferingEventId: null,
    modifyOfferingEvent: null,
    loading: false,
    error: null,
  })),
  on(programActions.modifyProgram, (state, { modifyProgramEventId, modifyProgramEvent }) => ({
    ...state,
    modifyProgramEventId,
    modifyProgramEvent,
    loading: true,
    error: null,
  })),
  on(programActions.modifyProgramSuccess, (state) => ({
    ...state,
    modifyProgramEventId: null,
    modifyProgramEvent: null,
    loading: false,
    error: null,
  })),

  on(programActions.clearProgram, (state) => ({ ...state, programId: null, program: null })),
  on(programActions.programError, (state, { error }) => ({ ...state, error, loadingWithCriteria: false })),
  on(programActions.clearProgramError, (state) => ({ ...state, error: null, loading: false })),
  on(programActions.clearProgramList, (state) => ({ ...state, programList: null, programsLoaded: false })),
  on(programActions.clearProgramListWithCriteria, (state) => ({
    ...state,
    programListWithCriteria: null,
    programsLoadedWithCriteria: false,
  })),
  on(programActions.clearState, () => ({ ...initialState })),
  on(programActions.getProgram, (state, { programId, at }) => ({
    ...state,
    programId,
    program: null,
    at,
    error: null,
    loading: true,
  })),

  on(programActions.getParentProgram, (state, { parentProgramId, at }) => ({
    ...state,
    parentProgramId,
    parentProgram: null,
    at,
    error: null,
    loading: true,
  })),
  on(programActions.getParentProgramSuccess, (state, { parentProgram }) => ({ ...state, parentProgram, error: null, loading: false })),
  on(programActions.getProgramSuccess, (state, { program }) => ({ ...state, program, error: null, loading: false })),
  on(programActions.getProgramList, (state) => ({ ...state, error: null, loading: true })),
  on(programActions.getProgramListSuccess, (state, { programList, lastEvaluatedKey }) => ({
    ...state,
    programList,
    programListLastEvaluatedKey: lastEvaluatedKey,
    error: null,
    programsLoaded: true,
    loading: false,
  })),
  on(programActions.getProgramListWithCriteria, (state) => ({ ...state, programListWithCriteria: null, error: null, loadingWithCriteria: true })),
  on(programActions.getProgramListWithCriteriaSuccess, (state, { programListWithCriteria }) => ({
    ...state,
    programListWithCriteria,
    error: null,
    programsLoadedWithCriteria: true,
    loadingWithCriteria: false,
  })),
  on(programActions.getLookupProgram, (state, { programLookupId, at }) => ({
    ...state,
    at,
    programLookupId,
    lookupProgramError: null,
  })),
  on(programActions.clearLookupProgram, (state) => ({ ...state, programLookupId: null, lookupProgram: null,  lookupProgramError: null })),
  on(programActions.clearLookupProgramError, (state) => ({ ...state, lookupProgramError: null })),
  on(programActions.getLookupProgramSuccess, (state, { lookupProgram }) => ({ ...state, lookupProgram, lookupProgramError: null })),
  on(programActions.lookupProgramError, (state, { lookupProgramError }) => ({ ...state, lookupProgram: null, lookupProgramError })),
);
