// app/core/auth/auth.reducer.ts

/* eslint-disable sonarjs/no-duplicate-string, import/no-extraneous-dependencies */

import { createReducer, on } from '@ngrx/store';
import { v1 as ApiDops } from '@xform/api-dops';
import { v1 as ApiFops } from '@xform/api-fops';
import { ModifyDeveloperEvent } from '@xform/api-dops/dist/api/v1';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { MFASetupResponse } from '../../modules/shared/services/cognito-service/cognito-auth.service';
import * as authActions from './auth.actions';

export interface State {
  user?: ApiDops.DeveloperView;
  userId?: string;
  loadingUser?: boolean;
  selectedAccessClaim?: string;
  previousEnvironment?: string;
  selectedEnvironment?: string
  modifyUserEventId?: string;
  modifyUserEvent?: ModifyDeveloperEvent;
  userBureauId?: string;
  bureau?: ApiDops.BureauView;
  bureauLoading?: boolean;
  error?: ApiDops.Error;
  generalError?: ApiDops.Error;
  isAuthenticated?: boolean;
  isMFASetup?: boolean;
  loggingIn: boolean;
  challengeName?: string;
  mfaSetupResponse?: MFASetupResponse;
  signInLoading?: boolean;
  mfaAuthLoading?: boolean;
  jwt?: string;
  bureauList?: ApiFops.BureauList;
  loading?: boolean;
}

const initialState: State = {
  loadingUser: false,
  isAuthenticated: false,
  loggingIn: false,
  isMFASetup: false,
  mfaAuthLoading: false,
};

const getUserId = (cognitoUserSession: CognitoUserSession): string => cognitoUserSession.getIdToken().decodePayload()['custom:developerId'] ?? cognitoUserSession.getIdToken().decodePayload()['custom:opsId'];

export const reducers = createReducer(
  initialState,
  on(authActions.authError, (state, { error }) => ({
    ...state,
    error,
    isAuthenticated: false,
    loadingUser: false,
    loggingIn: false,
    signInLoading: false,
    mfaAuthLoading: false,
  })),
  on(authActions.generalError, (state, { generalError }) => ({
    ...state,
    generalError,
    mfaAuthLoading: false,
  })),
  on(authActions.clearGeneralError, (state) => ({
    ...state,
    generalError: null,
  })),
  on(authActions.clearAuthError, (state) => ({
    ...state,
    error: null,
  })),
  on(authActions.getOpsUserSuccess, (state, { opsUser }) => (
    {
      ...state,
      user: opsUser as any,
    }
  )),
  on(authActions.getUser, (state) => (
    {
      ...state,
      loadingUser: true,
    }),
  ),
  on(authActions.getUserSuccess, (state, { user }) => (
    {
      ...state,
      user,
      loadingUser: false,
      loggingIn: false,
    }),
  ),
  on(authActions.opsDevBureauSelected, (state, { bureau }) => (
    {
      ...state,
      bureau,
      userBureauId: bureau.bureauId,
    }
  ),
  ),
  on(authActions.getBureau, (state) => (
    {
      ...state,
      generalError: null,
      loading: true,
    }),
  ),
  on(authActions.getBureauSuccess, (state, { bureau }) => (
    {
      ...state,
      bureau,
      generalError: null,
      loading: false,
    }),
  ),
  on(authActions.getBureausForOpsDevSuccess, (state, { bureauList }) => (
    {
      ...state,
      bureauList,
    }),
  ),
  on(authActions.confirmSignIn, (state) => (
    {
      ...state,
      mfaAuthLoading: true,
    }),
  ),
  // TODO make sure amplify does null checks
  on(authActions.loginSuccess, (state, { cognitoUserSession, isMFASetup }) => ({
    ...state,
    jwt: cognitoUserSession.getIdToken().getJwtToken(),
    userId: getUserId(cognitoUserSession),
    userBureauId: cognitoUserSession.getIdToken().decodePayload()['custom:bureauId'],
    isAuthenticated: true,
    loggingIn: true,
    isMFASetup: Boolean(isMFASetup),
    mfaAuthLoading: false,
  })),
  on(authActions.confirmSignInSuccess, (state, { cognitoUserSession }) => ({
    ...state,
    jwt: cognitoUserSession.getIdToken().getJwtToken(),
    userId: getUserId(cognitoUserSession),
    userBureauId: cognitoUserSession.getIdToken().decodePayload()['custom:bureauId'],
    isAuthenticated: true,
    loggingIn: true,
    isMFASetup: true,
    mfaAuthLoading: false,
  })),
  on(authActions.modifyUser, (state, { modifyUserEventId, modifyUserEvent }) => ({
    ...state,
    modifyUserEventId,
    modifyUserEvent,
    loadingUser: true,
  })),
  on(authActions.signIn, (state) => (
    {
      ...state,
      signInLoading: true,
    }),
  ),
  on(authActions.modifyUserSuccess, (state) => ({
    ...state,
    modifyUserEventId: null,
    modifyUserEvent: null,
    error: null,
  })),
  on(authActions.updateAccessClaim, (state, { environment, access }) => ({
    ...state,
    previousEnvironment: state.selectedEnvironment,
    selectedAccessClaim: access,
    selectedEnvironment: environment,
  })),
  on(authActions.updateEnvironment, (state, { instance }) => ({
    ...state,
    selectedEnvironment: instance,
  })),
  on(authActions.setAuthenticatedToFalse, () => ({
    ...initialState,
  })),
  on(authActions.setAuthenticatedToTrue, (state) => ({
    ...state,
    isAuthenticated: true,
  })),
  on(authActions.verifyMFATokenSuccess, (state, { cognitoUserSession }) => ({
    ...state,
    jwt: cognitoUserSession.getIdToken().getJwtToken(),
    userId: getUserId(cognitoUserSession),
    userBureauId: cognitoUserSession.getIdToken().decodePayload()['custom:bureauId'],
    isAuthenticated: true,
    isMFASetup: true,
    mfaAuthLoading: false,
  })),
  on(authActions.signInSuccess, (state, { challengeName }) => ({
    ...state,
    challengeName,
    signInLoading: false,
    error: null,
  }),
  ),
  on(authActions.setupMFASuccess, (state, { mfaSetupResponse }) => ({
    ...state,
    mfaSetupResponse,
  }),
  ),
);

/* eslint-enable */
