// app/core/auth/access-claim.util.ts

import { AccessRoles } from '@src/app/shared/directives/access.map';
import * as jwt from 'jsonwebtoken';
import { environment } from '../../../environments/environment';

const groupFlagLength = 6;

export const getIndividualIdFromGroupId = (groupId: string): string =>
  groupId.substring(0, groupId.length - groupFlagLength);

export interface JWTClaims {
  [key: string]: AccessRoles;
}

export function getAccessClaims(token: string): JWTClaims {
  const decoded = jwt.decode(token, { complete: true });
  const payload = decoded?.payload as jwt.JwtPayload;
  return  payload?.['custom:accessClaims'] as string ? JSON.parse(payload?.['custom:accessClaims'] as string) as JWTClaims : null;
}

export const isOpsDev = (claims: JWTClaims): boolean => claims?.[`${environment.MAIN_ENV}`] === AccessRoles.OpsDev;
