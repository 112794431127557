// app/modules/core-services/programs/state/client.actions.ts

/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-classes-per-file */

import { createAction, props } from '@ngrx/store';
import { v1 as ApiDops } from '@xform/api-dops';
import { SearchParams } from '../../../shared/services/search/search';

export enum ClientActionTypes {
    ClearState = '[Client] Clear State',
    ClearClientList = '[Client] Clear Client List',
    ClearClient = '[Client]] Clear Client',
    ClientError = '[Client] Get Client Error',
    ClearClientError = '[Client] Clear Client Error',
    GetClient = '[Client] Get Client',
    GetClientSuccess = '[Client] Get Client Success',
    GetClientList = '[Client] Get Client List',
    GetClientListSuccess = '[Client] Get Client List Success',
}

export const clearState = createAction(ClientActionTypes.ClearState);
export const clearClientList = createAction(ClientActionTypes.ClearClientList);
export const clearClient = createAction(ClientActionTypes.ClearClient);
export const clientError = createAction(ClientActionTypes.ClientError, props<{error: ApiDops.Error}>());
export const clearClientError = createAction(ClientActionTypes.ClearClientError);
export const getClient = createAction(ClientActionTypes.GetClient, props<{clientId: string, at: string}>());
export const getClientSuccess = createAction(ClientActionTypes.GetClientSuccess, props<{client: ApiDops.ClientView}>());
export const getClientList = createAction(ClientActionTypes.GetClientList);
export const getClientListSuccess = createAction(ClientActionTypes.GetClientListSuccess, props<{clientList: ApiDops.ClientList}>());
