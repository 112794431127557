// app/modules/reconciliation/reconciliation.reducer.ts

import { createReducer, on } from '@ngrx/store';
import { v1 as ApiDops } from '@xform/api-dops';
import { v1 as ApiReport } from '@xform/api-report';
import * as reconciliationActionTypes from './reconciliation.actions';
import { PaymentSourceType, ReconciliationReportType, SIGISReportType } from './reconciliation.service';

export interface State {
  fileType?: ReconciliationReportType;
  fileList?: any[];
  paymentSourceType?: PaymentSourceType;
  fileSourceType?: SIGISReportType;
  paymentFileList?: any[];
  error?: ApiDops.Error;
  loading: boolean;
  signedUrl?: string;
  signedUrlError?: ApiReport.Error;
}

const initialState: State = {
  loading: false,
};

export const reducers = createReducer(
  initialState,
  on(reconciliationActionTypes.getFileList, (state, { fileType }) => ({ ...state, fileType, error: null, loading: true})),
  on(reconciliationActionTypes.getFileListSuccess, (state, { fileList }) => ({ ...state, fileList, error: null, loading: false })),
  on(reconciliationActionTypes.getSignedUrlSuccess, (state, { signedUrl }) => ({ ...state, signedUrl })),
  on(reconciliationActionTypes.getPaymentFileList, (state, { paymentSourceType }) => ({ ...state, paymentSourceType, error: null, loading: true})),
  on(reconciliationActionTypes.getMerchantListFileList, (state, { fileSourceType }) => ({ ...state, fileSourceType, error: null, loading: true})),
  on(reconciliationActionTypes.clearFileList, (state) => ({ ...state, fileList: null, error: null, loading: false })),
  on(reconciliationActionTypes.fileListError, (state, { error }) => ({ ...state, error, loading: false })),
  on(reconciliationActionTypes.clearFileListError, (state) => ({ ...state, error: null, loading: false })),
  on(reconciliationActionTypes.signedUrlError, (state, { error }) => ({ ...state, signedUrlError: error, loading: false })),
  on(reconciliationActionTypes.clearSignedUrlError, (state) => ({ ...state, signedUrlError: null, loading: false })),
  on(reconciliationActionTypes.clearSignedUrl, (state) => ({ ...state, signedUrl: null })),
);
