// app/modules/notification/event-stream/state/event-stream.actions.ts

import { createAction, props } from '@ngrx/store';
import { v1 as ApiDops } from '@xform/api-dops';

export enum EventStreamSettingsActionTypes {
  EventStreamSettingsError = '[EventStreamSettings] Get EventStreamSettings Error',
  ClearEventStreamSettingsError = '[EventStreamSettings] Clear EventStreamSettings Error',
  GetEventStreamSettings = '[EventStreamSettings] Get EventStreamSettings',
  GetEventStreamSettingsSuccess = '[EventStreamSettings] Get EventStreamSettings Success',
}

export const eventStreamSettingsError = createAction(EventStreamSettingsActionTypes.EventStreamSettingsError, props<{ error: ApiDops.Error }>());
export const clearEventStreamSettingsError = createAction(EventStreamSettingsActionTypes.ClearEventStreamSettingsError);
export const getEventStreamSettings = createAction(EventStreamSettingsActionTypes.GetEventStreamSettings, props<{ eventStreamSettingsId: string }>());
export const getEventStreamSettingsSuccess = createAction(EventStreamSettingsActionTypes.GetEventStreamSettingsSuccess, props<{ eventStreamSettings: ApiDops.EventStreamSettingsView }>());
