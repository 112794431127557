// app/modules/app-shell/header/header.component.ts

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Auth } from 'aws-amplify';
import { Store } from '@ngrx/store';
import { UIOption } from '@xform/xformative-angular';
import { Subscription } from 'rxjs/internal/Subscription';
import { filter, first, map, tap, withLatestFrom } from 'rxjs/operators';
import { updateAccessClaim, updateEnvironment } from '@app/core/auth/auth.actions';
import { getAccessClaims, isOpsDev, JWTClaims } from '@src/app/core/auth/access-claim.util';
import { Router } from '@angular/router';
import { selectAccessClaim, selectBureau, selectEnvironment, selectJWT, selectUserProfile } from '../../../core/auth/auth.selectors';

interface GenClaim {
  env?: string,
  access?: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {

  @Input() isScreenSmall: boolean;

  @Output() public sidenavToggle = new EventEmitter();

  environmentOptions: UIOption[] = [];

  watcher: Subscription;

  claims: JWTClaims;

  userProfile$ = this.store.select(selectUserProfile);

  selectedInstance$ = this.store.select(selectEnvironment).pipe(withLatestFrom(this.store.select(selectAccessClaim)));

  selectedEnv: GenClaim;

  selected$ = this.store.select(selectJWT).pipe(
    withLatestFrom(this.store.select(selectBureau), this.selectedInstance$),
    map(([jwt, selectedBureau, selectedInstance]) => {
      this.claims = getAccessClaims(jwt);

      if (this.claims) {
        Object.entries(this.claims).forEach(claim => {
          if (!this.environmentOptions.find(option => option.value === claim[0])) {
            this.environmentOptions.push({
              displayLabel: `${claim[0].toUpperCase()}`,
              value: { env: claim[0], access: claim[1] },
            });
          }
        });

        if (isOpsDev(this.claims)) {
          // filter environment options with selected bureau

          const envNames = selectedBureau?.profile?.envs.map((env) => env.name);
          this.environmentOptions = this.environmentOptions.filter((env) => envNames?.includes(env.displayLabel.toLowerCase()));
        }
      }

      if (this.environmentOptions.length && typeof selectedInstance[0] === 'undefined') {
        this.selectedEnv = { env: this.environmentOptions[0]?.value?.env, access: this.environmentOptions[0]?.value.access };
        this.store.dispatch(updateAccessClaim({ environment: this.environmentOptions[0]?.value?.env, access: this.environmentOptions[0]?.value.access }));
      }

      return this.environmentOptions;
    }),
  );

  constructor(
    private store: Store,
    private router: Router,
  ) {

    this.selectedInstance$.pipe(first(), filter(inst => typeof inst[0] !== 'undefined')).subscribe(instance =>{
      this.selectedEnv = { env: instance[0], access: instance[1] };
    });

  }

  public onToggleSidenav(): void {
    this.sidenavToggle.emit();
  }

  async signOut(): Promise<void> {
    await Auth.signOut();
  }

  async routeToProfile(): Promise<void> {
    await this.router.navigate(['/user-profile']);
  }

  environmentChange(accessClaim: GenClaim): void {
    this.store.dispatch(updateAccessClaim({ environment: accessClaim.env, access: accessClaim.access }));
  }

  compareEnviroments(selected: GenClaim, option : GenClaim): boolean {
    return typeof selected !== 'undefined' && typeof option !== 'undefined' &&  selected.env === option.env && selected.access === option.access;
  }
}
