// app/modules/core-services/wallet/wallet.actions.ts

import { createAction, props } from '@ngrx/store';
import { v1 as ApiLedgerV1 } from '@xform/api-ledger';

export enum WalletActionTypes {
  ClearError = '[Wallet] Clear Error',
  HandleBalanceError = '[Wallet] Handle Balance Error',
  HandleTransactionError = '[Wallet] Handle Tranasction Error',
  LoadWallet = '[Wallet] Load Wallet',
  GetAccountBalancesSuccess = '[Wallet] Get Account Balances Success',
  GetTransactionSuccess = '[Wallet] Get Transactions Success',
  GetPreviousPageOfTransactions = '[Wallet] Get Previous Page of Transactions',
  GetNextPageOfTransactions = '[Wallet] Get Next Page of Transactions',
}

export const clearError = createAction(WalletActionTypes.ClearError);
export const handleBalanceError = createAction(WalletActionTypes.HandleBalanceError, props<{balanceError: ApiLedgerV1.Error}>());
export const handleTransactionError = createAction(WalletActionTypes.HandleTransactionError, props<{transactionError: ApiLedgerV1.Error}>());
export const loadWallet = createAction(WalletActionTypes.LoadWallet, props<{groupId: string, programId?: string }>());
export const getAccountBalancesSuccess = createAction(WalletActionTypes.GetAccountBalancesSuccess, props<{accountBalances: ApiLedgerV1.BalancesWithAccountInfo[]}>());
export const getTransactionSuccess = createAction(WalletActionTypes.GetTransactionSuccess, props<{transactions: ApiLedgerV1.PaginatedTransactions}>());
export const getNextPageOfTransactions = createAction(WalletActionTypes.GetNextPageOfTransactions);
export const getPreviousPageOfTransactions = createAction(WalletActionTypes.GetPreviousPageOfTransactions);
