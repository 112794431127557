// app/modules/developers/developer.reducer.ts

import { createReducer, on } from '@ngrx/store';
import { v1 as ApiDops } from '@xform/api-dops';
import * as developerActions from './developer.actions';

export interface State {
  developerId?: string;
  developersLoaded: boolean;
  newDeveloperEventId?: string;
  newDeveloperEvent?: ApiDops.NewDeveloperEvent;
  modifyDeveloperEventId?: string;
  modifyDeveloperEvent?: ApiDops.ModifyDeveloperEvent;
  cancelDeveloperEventId?: string;
  cancelDeveloperEvent?: ApiDops.CancelDeveloperEvent;
  reactivateDeveloperEventId?: string;
  reactivateDeveloperEvent?: ApiDops.ReactivateDeveloperEvent;
  at?: string;
  developer?: ApiDops.DeveloperView;
  developerList?: ApiDops.DeveloperList;
  error?: ApiDops.Error;
  loading: boolean;
}

const initialState: State = {
  loading: false,
  developersLoaded: false,
};

export const reducers = createReducer(
  initialState,
  on(developerActions.developerError, (state, { error }) => ({...state, error, loading: false })),
  on(developerActions.clearDeveloperError, (state) => ({...state, error: null})),
  on(developerActions.getDeveloper, (state, { developerId }) => ({...state, developerId, error: null, loading: true})),
  on(developerActions.getDeveloperSuccess, (state, { developer }) => ({...state, developer, error: null, loading: false})),
  on(developerActions.getDeveloperList, (state) => ({...state, error: null, loading: true})),
  on(developerActions.getDeveloperListSuccess, (state, { developerList }) => ({...state, developerList, error: null, developersLoaded: true, loading: false})),
  on(developerActions.modifyDeveloper, (state, {modifyDeveloperEventId, modifyDeveloper}) => ({...state, modifyDeveloperEventId, modifyDeveloperEvent: modifyDeveloper, error: null, loading: true})),
  on(developerActions.modifyDeveloperSuccess, (state) => ({...state, modifyDeveloperEvent: null, modifyDeveloperEventId: null, error: null, loading: false})),
  on(developerActions.newDeveloper, (state, {newDeveloperEventId, newDeveloper}) => ({...state, newDeveloperEventId, newDeveloperEvent: newDeveloper, error: null, loading: true})),
  on(developerActions.newDeveloperSuccess, (state) => ({...state, newDeveloperEvent: null, newDeveloperEventId: null, error: null, loading: false})),
  on(developerActions.cancelDeveloper, (state, {cancelDeveloperEventId, cancelDeveloper}) => ({...state, cancelDeveloperEventId, cancelDeveloperEvent: cancelDeveloper, error: null, loading: true})),
  on(developerActions.cancelDeveloperSuccess, (state) => ({...state, cancelDeveloperEvent: null, cancelDeveloperEventId: null, error: null, loading: false})),
  on(developerActions.reactivateDeveloper, (state, {reactivateDeveloperEventId, reactivateDeveloper}) => ({...state, reactivateDeveloperEventId, reactivateDeveloperEvent: reactivateDeveloper, error: null, loading: true})),
  on(developerActions.reactivateDeveloperSuccess, (state) => ({...state, reactivateDeveloperEvent: null, reactivateDeveloperEventId: null, error: null, loading: false})),
);
