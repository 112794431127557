// app/modules/core-services/wallet/wallet.reducer.ts
import { Action, createReducer, on } from '@ngrx/store';
import { v1 as ApiLedger } from '@xform/api-ledger';
import { v1 as HistoryV1 } from '@xform/history';
import * as walletActions from './wallet.actions';

export interface State {
  balanceError?: ApiLedger.Error;
  transactionError?: ApiLedger.Error;
  loading: boolean;
  accountBalances?: ApiLedger.BalancesWithAccountInfo[]
  transactions?: HistoryV1.PaginatedTransactions,
  groupId?: string,
  programId?: string,
  transactionPreviousTokenStack: string[]
}

const initialState: State = {
  loading: false,
  transactionPreviousTokenStack: [],
};

export const reducers = createReducer(
  initialState,
  on(walletActions.handleBalanceError, (state, { balanceError }) => ({ ...state, balanceError, loading: false })),
  on(walletActions.handleTransactionError, (state, { transactionError }) => ({ ...state, transactionError, loading: false })),
  on(walletActions.clearError, (state) => ({ ...state,  transactionError: null, balanceError: null, loading: false})),
  on(walletActions.getAccountBalancesSuccess, (state, { accountBalances }) => ({...state, accountBalances, loading: false})),
  on(walletActions.getTransactionSuccess, (state, { transactions }) => ({...state, transactions, loading: false})),
  on(walletActions.loadWallet, (state, { groupId, programId }) => ({...state, groupId, programId, transactions: null, accountBalances: null, loading: true, transactionPreviousTokenStack: []})),
  on(walletActions.getNextPageOfTransactions, (state) => {
    if (state.transactions?.state?.nextToken) {
      return {
        ...state,
        loading: true,
        transactionPreviousTokenStack: [...state.transactionPreviousTokenStack, state.transactions.state.nextToken],
      };
    }
    return {...state};
  }),
  on(walletActions.getPreviousPageOfTransactions, (state) => {
    const stack = [...state.transactionPreviousTokenStack];
    stack.pop();
    return {
      ...state, loading: true, transactions: null, accountBalances: null, transactionPreviousTokenStack: stack,
    };
  }),
);
