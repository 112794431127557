// app/app.module.ts

// angular dependencies
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, SecurityContext } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
import { FullRouterStateSerializer, routerReducer, RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ToastrModule } from 'ngx-toastr';
import { MarkdownModule } from 'ngx-markdown';
import { Chart, registerables } from 'chart.js';
import { XPSMainHttpService } from '@app/shared/services/xps-main-http.service';
import { routes } from '../app/modules/app-shell/app-shell.routing';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AuthEffects } from './core/auth/auth.effects';
import { AuthGuard } from './core/auth/auth.guard';
import { reducers as userReducer } from './core/auth/auth.reducer';
import { AuthService } from './core/auth/auth.service';
import { EnvGuard } from './core/auth/env.guard';
import { HttpInterceptorProviders } from './core/interceptor-provider';
import { AppShellModule } from './modules/app-shell/app-shell.module';
import { SharedModule } from './modules/shared/shared.module';
import { CustomRouterStateSerializer } from './modules/shared/utils/utils';
import { XPSHttpService } from './shared/services/xps-http.service';
import { BureauService } from './modules/bureau-profile/bureau.service';
import { EncryptionUtilitiesComponent } from './modules/core-services/encryption-utilities/encryption-utilities/encryption-utilities.component';
import { ClockErrorComponent } from './shared/components/clock-error/clock-error.component';
import { reducers as hydrationMetaReducer, MetaReducers } from './store';
import { HydrationEffects } from './store/hydration.effects';
// ngrx
// local dependencies
const config: ExtraOptions = {
  useHash: false,
  enableTracing: false,
  relativeLinkResolution: 'legacy',
};

@NgModule({
  declarations: [
    AppComponent,
    EncryptionUtilitiesComponent,
    ClockErrorComponent,
  ],
  imports: [
    AppShellModule,
    BrowserAnimationsModule,
    BrowserModule,
    SharedModule,
    HttpClientModule,
    RouterModule.forRoot(routes, { useHash: false, relativeLinkResolution: 'legacy' }),
    StoreModule.forRoot({
      auth: userReducer,
      router: routerReducer,
    }),
    StoreModule.forRoot(hydrationMetaReducer, { metaReducers: MetaReducers }),
    StoreRouterConnectingModule.forRoot({
      serializer: FullRouterStateSerializer,
      stateKey: 'router',
    }),
    EffectsModule.forRoot([AuthEffects, HydrationEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: !environment.production,
    }),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    MarkdownModule.forRoot({ sanitize: SecurityContext.NONE }),
  ],
  providers: [
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    AuthGuard,
    BureauService,
    EnvGuard,
    HttpInterceptorProviders,
    XPSHttpService,
    XPSMainHttpService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor(authService: AuthService,
  ) {
    Chart.register(...registerables);
  }
}
