// app/store/index.ts

import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { routerReducer, RouterState } from '@ngrx/router-store';
import * as authReducer from '../core/auth/auth.reducer';
import * as bureauReducer from '../modules/bureau-profile/bureau.reducer';
import * as accessKeyReducer from '../modules/core-services/api-keys/accessKey.reducer';
import * as cardReducer from '../modules/core-services/cards/state/card.reducer';
import * as ledgerReducer from '../modules/core-services/ledger/state/ledger.reducer';
import * as organizationReducer from '../modules/core-services/organization/state/organization.reducer';
import * as paymentReducer from '../modules/core-services/payments/state/payment.reducer';
import * as programReducer from '../modules/core-services/programs/state/program.reducer';
import * as clientReducer from '../modules/core-services/programs/state/client.reducer';
import * as simulatorReducer from '../modules/core-services/simulator/simulator.reducer';
import * as walletReducer from '../modules/core-services/wallet/wallet.reducer';
import * as metricReportReducer from '../modules/dashboard/state/metric-reports.reducer';
import * as developerReducer from '../modules/developers/developer.reducer';
import * as invoiceReducer from '../modules/invoices/invoice.reducer';
import * as eventStreamSettingsReducer from '../modules/notification/event-stream/state/event-stream.reducer';
import * as webhookReducer from '../modules/notification/web-hooks/webhook.reducer';
import * as reconciliationReducer from '../modules/reconciliation/reconciliation.reducer';

import { hydrationMetaReducer } from './hydration.reducer';

export interface RootState {
  auth: authReducer.State,
  router: RouterState,
  bureau: bureauReducer.State,
  accessKey: accessKeyReducer.State,
  card: cardReducer.State,
  ledger: ledgerReducer.State,
  organization: organizationReducer.State,
  payment: paymentReducer.State,
  program: programReducer.State,
  client: clientReducer.State,
  simulator: simulatorReducer.State,
  wallet: walletReducer.State,
  metricReport: metricReportReducer.State,
  developers: developerReducer.State,
  invoices: invoiceReducer.State,
  eventStreamSettings: eventStreamSettingsReducer.State,
  webhook: webhookReducer.State,
  reconciliation: reconciliationReducer.State
}

export const reducers: ActionReducerMap<RootState> = {
  auth: authReducer.reducers,
  router: routerReducer,
  bureau: bureauReducer.reducers,
  accessKey: accessKeyReducer.reducers,
  card: cardReducer.reducers,
  ledger: ledgerReducer.reducers,
  organization: organizationReducer.reducers,
  payment: paymentReducer.reducers,
  program: programReducer.reducers,
  client: clientReducer.reducers,
  simulator: simulatorReducer.reducers,
  wallet: walletReducer.reducers,
  metricReport: metricReportReducer.reducers,
  developers: developerReducer.reducers,
  invoices: invoiceReducer.reducers,
  eventStreamSettings: eventStreamSettingsReducer.reducers,
  webhook: webhookReducer.reducers,
  reconciliation: reconciliationReducer.reducers,
};

export const MetaReducers: MetaReducer[] = [hydrationMetaReducer];
