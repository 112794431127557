// app/modules/shared/icons/icons.module.ts

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { adjustments, arrowLeft, arrowRight, atSymbol, badgeCheck, check, chevronDown, chevronLeft, chevronRight, chevronUp, clipboard, clipboardCopy, creditCard, deviceMobile, documentSearch, dotsVertical, hashtag, HeroIconsModule, identification, informationCircle, key, library, lockClosed, lockOpen, mail, map, menu, officeBuilding, pause, pencilSolid, phone, play, plusCircle, refresh, reply, rewind, saveAs, sortDescending, template, trash, user, users, userSolid, variable, viewGridAdd, x } from 'ng-heroicons';
@NgModule({
  declarations: [],
  imports: [
    HeroIconsModule.withIcons({
      adjustments,
      arrowLeft,
      arrowRight,
      atSymbol,
      badgeCheck,
      check,
      chevronDown,
      chevronLeft,
      chevronRight,
      chevronUp,
      clipboard,
      clipboardCopy,
      creditCard,
      deviceMobile,
      documentSearch,
      dotsVertical,
      hashtag,
      identification,
      informationCircle,
      key,
      library,
      lockClosed,
      lockOpen,
      mail,
      map,
      menu,
      officeBuilding,
      pause,
      pencilSolid,
      phone,
      play,
      plusCircle,
      refresh,
      reply,
      rewind,
      saveAs,
      sortDescending,
      template,
      trash,
      user,
      users,
      userSolid,
      variable,
      viewGridAdd,
      x,
    }),
    CommonModule,
  ],
  exports: [
    HeroIconsModule,
  ],
})
export class IconsModule { }
