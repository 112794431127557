// app/modules/notification/web-hooks/webhook.reducer.ts

import { createReducer, on } from '@ngrx/store';
import { v1 as ApiDops } from '@xform/api-dops';
import * as webhookActions from './webhook.actions';

export interface State {
  modifyWebhookEvent?: ApiDops.ModifyWebhookEvent;
  modifyWebhookEventId?: string,
  webhook?: ApiDops.WebhookView;
  error?: ApiDops.Error;
  loading: boolean;
}

const initialState: State = {
  loading: false,
};

export const reducers = createReducer(
  initialState,
  on(webhookActions.webhookError, (state, { error }) => ({...state, error})),
  on(webhookActions.clearWebhookError, (state) => ({...state, error: null})),
  on(webhookActions.getWebhook, (state) => ({...state, error: null, loading: true})),
  on(webhookActions.getWebhookSuccess, (state, {webhook}) => ({...state, webhook, error: null, loading: false})),
  on(webhookActions.modifyWebhook, (state, {modifyWebhookEventId, modifyWebhook}) => ({
    ...state,
    modifyWebhookEventId,
    modifyWebhookEvent: modifyWebhook,
    error: null,
    loading: true,
  })),
  on(webhookActions.modifyWebhookSuccess, (state) => ({
    ...state,
    modifyWebhookEvent: null,
    modifyWebhookEventId: null,
    error: null,
    loading: false,
  })),
);
