// app/modules/core-services/cards/state/card.reducer.ts

import { createReducer, on } from '@ngrx/store';
import { v1 as ApiDops } from '@xform/api-dops';
import * as cardActions from './card.actions';

export interface State {
  cardsLoadedWithCriteria: boolean;
  at?: string;
  cardListWithCriteria?: ApiDops.SearchResults;
  error?: ApiDops.Error;
  loadingWithCriteria: boolean;
  searchTerm?: string;
  searchEndDateTime?: string;
}

const initialState: State = {
  loadingWithCriteria: false,
  cardsLoadedWithCriteria: false,
};

export const reducers = createReducer(
  initialState,
  on(cardActions.cardError, (state, { error }) => ({ ...state, error, loadingWithCriteria: false })),
  on(cardActions.clearCardError, (state) => ({ ...state, error: null })),
  on(cardActions.getCardListWithCriteria, (state, { searchTerm, searchEndDateTime }) => ({ ...state, cardListWithCriteria: null, error: null, loadingWithCriteria: true, searchTerm, searchEndDateTime })),
  on(cardActions.getCardListWithCriteriaSuccess, (state, { cardListWithCriteria }) => ({ ...state, cardListWithCriteria, error: null, cardsLoadedWithCriteria: true, loadingWithCriteria: false })),
);
