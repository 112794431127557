// app/modules/bureau-profile/bureau.reducer.ts

import { createReducer, on } from '@ngrx/store';
import { v1 as ApiDops } from '@xform/api-dops';
import * as bureauActions from './bureau.actions';

export interface State {
  bureau?: ApiDops.BureauView;
  error?: ApiDops.Error;
  loading: boolean;
}

const initialState: State = {
  loading: false,
};

export const reducers = createReducer(
  initialState,
  on(bureauActions.bureauError, (state, {error}) => ({...state, error})),
  on(bureauActions.clearBureauError, (state) => ({...state, error: null})),
  on(bureauActions.getBureau, (state) => ({...state, error: null, loading: true})),
  on(bureauActions.getBureauSuccess, (state, { bureau }) => ({...state, bureau, error: null, loading: false})),
);
