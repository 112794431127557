// app/modules/bureau-profile/bureau.actions.ts

import { createAction, props } from '@ngrx/store';
import { v1 as ApiDops } from '@xform/api-dops';

export enum BureauActionTypes {
    BureauError = '[Bureau] Get Program Error',
    ClearBureauError = '[Bureau] Clear Program Error',
    GetBureau = '[Bureau] Get Bureau',
    GetBureauSuccess = '[Bureau] Get Bureau Success',
    ModifyBureau = '[Bureau] Modify Bureau',
    ModifyBureauSuccess = '[Bureau] Modify Bureau Success',
}

export const bureauError = createAction(BureauActionTypes.BureauError, props<{error: ApiDops.Error}>());
export const clearBureauError = createAction(BureauActionTypes.ClearBureauError);
export const getBureau = createAction(BureauActionTypes.GetBureau);
export const getBureauSuccess = createAction(BureauActionTypes.GetBureauSuccess, props<{bureau: ApiDops.BureauView}>());