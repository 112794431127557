// app/modules/shared/shared.module.ts

import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule as  XFAngularModule } from '@xform/xformative-angular';
import { SafePipe } from '../../shared/pipes/safe.pipe';
import { DirectivesModule } from '../../shared/directives/directives.module';
import { MaskPipe } from '../../shared/pipes/mask.pipe';
import { UIOptionPipe } from '../../shared/pipes/ui-option.pipe';
import { ChartComponent } from './chart/chart.component';
import { IconsModule } from './icons/icons.module';
import { MetricTableComponent } from './metric-table/metric-table.component';

@NgModule({
  declarations: [
    ChartComponent,
    SafePipe,
    MaskPipe,
    UIOptionPipe,
    MetricTableComponent,
  ],
  imports: [
    CommonModule,
    // TODO delete FormsModule and refactor checkbox-group
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    IconsModule,
    FlexLayoutModule,
    RouterModule,
    XFAngularModule,
  ],
  exports: [
    ChartComponent,
    MaskPipe,
    SafePipe,
    UIOptionPipe,
    DirectivesModule,
    FlexLayoutModule,
    IconsModule,
    MetricTableComponent,
    ReactiveFormsModule,
    XFAngularModule,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],

})
export class SharedModule {
}
