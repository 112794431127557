// app/modules/core-services/simulator/simulator.actions.ts

import { createAction, props } from '@ngrx/store';
import { v1 as ApiSimulatorV2 } from '@xform/api-simulator';

export enum SimulatorActionTypes {
    NewAuthorization = '[Simulator] New Authorization',
    NewClearing = '[Simulator] New Clearing',
    NewReversal = '[Simulator] New Reversal',
    NewReturn = '[Simulator] New Return',
    ClearResponse = '[Simulator] Clear Message Response',
    HandleResponse = '[Simulator] Handle Response',
    ClearError = '[Simulator] Clear Error',
    HandleError = '[Simulator] Handle Error',
};

export const newAuthorization = createAction(SimulatorActionTypes.NewAuthorization, props<{authorizationEventId: string, authorizationMessage: ApiSimulatorV2.AuthorizationMessage}>());
export const newClearing = createAction(SimulatorActionTypes.NewClearing, props<{clearingEventId: string, clearingMessage: ApiSimulatorV2.ClearingMessage}>());
export const newReversal = createAction(SimulatorActionTypes.NewReversal, props<{reversalMessageId: string, reversalMessage: ApiSimulatorV2.ReversalMessage}>());
export const newReturn = createAction(SimulatorActionTypes.NewReturn, props<{returnMessageEventId: string, returnMessage: ApiSimulatorV2.Message}>());
export const clearResponse = createAction(SimulatorActionTypes.ClearResponse);
export const handleResponse = createAction(SimulatorActionTypes.HandleResponse, props<{responseMessage: ApiSimulatorV2.MessageResponse}>());
export const clearError = createAction(SimulatorActionTypes.ClearError);
export const handleError = createAction(SimulatorActionTypes.HandleError, props<{error: ApiSimulatorV2.Error}>());
