// app/modules/core-services/payments/state/payment.actions.ts

/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-classes-per-file */

import { createAction, props } from '@ngrx/store';
import { v1 as ApiDops } from '@xform/api-dops';
import { SearchParams } from '../../../shared/services/search/search';

export enum PaymentActionTypes {
    ClearState = '[Payment] Clear State',
    ClearPaymentListWithCriteria = '[Payment] Clear Payment List With Criteria',
    PaymentError = '[Payment] Get Payment Error',
    ClearPaymentError = '[Payment] Clear Payment Error',
    GetPaymentListWithCriteria = '[Payment] Get Payment List With Criteria',
    GetPaymentListWithCriteriaSuccess = '[Payment] Get Payment List With Criteria Success',
}

export const clearState = createAction(PaymentActionTypes.ClearState);
export const clearPaymentListWithCriteria = createAction(PaymentActionTypes.ClearPaymentListWithCriteria);
export const paymentError = createAction(PaymentActionTypes.PaymentError, props<{error: ApiDops.Error}>());
export const clearPaymentError = createAction(PaymentActionTypes.ClearPaymentError);
export const getPaymentListWithCriteria = createAction(PaymentActionTypes.GetPaymentListWithCriteria, props<Partial<SearchParams>>());
export const getPaymentListWithCriteriaSuccess = createAction(PaymentActionTypes.GetPaymentListWithCriteriaSuccess, props<{paymentListWithCriteria: ApiDops.SearchResults}>());
