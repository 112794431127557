// app/modules/core-services/api-keys/accessKey.reducer.ts

import { createReducer, on } from '@ngrx/store';
import { v1 as ApiDops } from '@xform/api-dops';
import * as accessKeyActions from './accessKey.actions';

export interface State {
  modifyAccessKeyEventId?: string;
  modifyAccessKeyEvent?: ApiDops.ModifyAccessKeyEvent;
  accessKeyId?: string;
  accessKey?: ApiDops.AccessKeyView;
  error?: ApiDops.Error;
  loading: boolean;
}

const initialState: State = {
  loading: false,
};

export const reducers = createReducer(
  initialState,
  on(accessKeyActions.accessKeyError, (state, { error }) => ({...state, error, loading: false})),
  on(accessKeyActions.clearAccessKeyError, (state) => ({...state, error: null})),
  on(accessKeyActions.getAccessKey, (state, { accessKeyId }) => ({...state, accessKeyId, error: null, loading: true})),
  on(accessKeyActions.getAccessKeySuccess, (state, { accessKey }) => ({...state, accessKey, error: null, loading: false})),
  on(accessKeyActions.modifyAccessKey, (state, {modifyAccessKeyEventId, modifyAccessKey}) => ({...state, modifyAccessKeyEventId, modifyAccessKeyEvent: modifyAccessKey, error: null, loading: true})),
  on(accessKeyActions.modifyAccessKeySuccess, (state) => ({...state, modifyAccessKeyEvent: null, modifyAccessKeyEventId: null, error: null, loading: false})),
);
