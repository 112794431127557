// app/modules/core-services/programs/state/client.reducer.ts

import { createReducer, on } from '@ngrx/store';
import { v1 as ApiDops } from '@xform/api-dops';
import * as clientActions from './client.actions';

export interface State {
  clientId?: string;
  clientsLoaded: boolean;
  at?: string;
  client?: ApiDops.ClientView;
  clientList?: ApiDops.ClientList;
  error?: ApiDops.Error;
  loading: boolean;
}

const initialState: State = {
  loading: false,
  clientsLoaded: false,
};

export const reducers = createReducer(
  initialState,
  on(clientActions.clientError, (state, { error }) => ({...state, client: null,  error })),
  on(clientActions.clearClientError, (state) => ({...state, error: null})),
  on(clientActions.getClient, (state, { clientId, at }) => ({...state, clientId, at, error: null, loading: true})),
  on(clientActions.getClientSuccess, (state, { client }) => ({...state, client, error: null, loading: false})),
  on(clientActions.getClientList, (state) => ({...state, error: null, loading: true})),
  on(clientActions.getClientListSuccess, (state, { clientList }) => ({...state, clientList, error: null, clientsLoaded: true, loading: false})),
  on(clientActions.clearClient, (state) => ({...state, client: null, error: null, clientsLoaded: true, loading: false})),
);
