// app/modules/core-services/api-keys/accessKey.actions.ts

import { createAction, props } from '@ngrx/store';
import { v1 as ApiDops } from '@xform/api-dops';

export enum AccessKeyActionTypes {
    AccessKeyError = '[AccessKey] Get Program Error',
    ClearAccessKeyError = '[AccessKey] Clear Program Error',
    GetAccessKey = '[AccessKey] Get Access Key',
    GetAccessKeySuccess = '[AccessKey] Get Access Key Success',
    ModifyAccessKey = '[AccessKey] Modify Access Key',
    ModifyAccessKeySuccess = '[AccessKey] Modify Access Key Success',
}

export const accessKeyError = createAction(AccessKeyActionTypes.AccessKeyError, props<{error: ApiDops.Error}>());
export const clearAccessKeyError = createAction(AccessKeyActionTypes.ClearAccessKeyError);
export const getAccessKey = createAction(AccessKeyActionTypes.GetAccessKey, props<{accessKeyId: string}>());
export const getAccessKeySuccess = createAction(AccessKeyActionTypes.GetAccessKeySuccess, props<{accessKey: ApiDops.AccessKeyView}>());
export const modifyAccessKey = createAction(AccessKeyActionTypes.ModifyAccessKey, props<{modifyAccessKeyEventId: string, modifyAccessKey: ApiDops.ModifyAccessKeyEvent}>());
export const modifyAccessKeySuccess = createAction(AccessKeyActionTypes.ModifyAccessKeySuccess);