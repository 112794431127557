<mat-sidenav-container [ngClass.gt-xs]="{'container-gt-xs': true}" [ngClass.xs]="{'container-xs': true}">
  <mat-sidenav #sidenav [opened]="!isScreenSmall" color="primary" mode="side">
    <div mat-subheader>Your Account</div>
    <mat-nav-list [disableRipple]="true">
      <mat-list-item routerLink="/dashboard" routerLinkActive="item-active">
        Dashboard
      </mat-list-item>
      <mat-list-item *hasPermission='permissions.ViewBureau' routerLink="/bureau-profile" routerLinkActive="item-active">
        Bureau Profile
      </mat-list-item>
      <mat-list-item routerLink="/developers" routerLinkActive="item-active">
        Developers
      </mat-list-item>
      <mat-list-item *hasPermission='permissions.ViewInvoice' routerLink="/invoices" routerLinkActive="item-active">
        Invoices
      </mat-list-item>
      <mat-list-item routerLink="/documents" routerLinkActive="item-active">
        Docs
      </mat-list-item>
    </mat-nav-list>
    <div mat-subheader>Core Services</div>
    <div class="second-subheader">

        <mat-nav-list [disableRipple]="true">
          <mat-expansion-panel [class.mat-elevation-z0]="true" displayMode="flat" hideToggle="true">
            <mat-expansion-panel-header> Organization </mat-expansion-panel-header>
            <mat-nav-list [disableRipple]="true" class="mat-nav-list-expansion-item">
              <a class="second-list-item-expansion" mat-list-item routerLink="/organization/documents" routerLinkActive="item-active">Docs</a>
              <a class="second-list-item-expansion" mat-list-item routerLink="/organization/events" routerLinkActive="item-active">Search</a>
              <a *ngIf="!(selected$ | async)?.includes('prod')" class="second-list-item-expansion" mat-list-item routerLink="/organization/apitoolkit" routerLinkActive="item-active">API Toolkit</a>
            </mat-nav-list>
          </mat-expansion-panel>
        </mat-nav-list>

        <mat-nav-list [disableRipple]="true">
          <mat-expansion-panel [class.mat-elevation-z0]="true" displayMode="flat" hideToggle="true">
            <mat-expansion-panel-header> Programs </mat-expansion-panel-header>
            <mat-nav-list [disableRipple]="true" class="mat-nav-list-expansion-item">
              <a class="second-list-item-expansion" mat-list-item routerLink="/programs/documents" routerLinkActive="item-active">Docs</a>
              <a class="second-list-item-expansion" mat-list-item routerLink="/programs/configuration" routerLinkActive="item-active">Manage</a>
              <a class="second-list-item-expansion" mat-list-item routerLink="/programs/events" routerLinkActive="item-active">Search</a>
              <a *ngIf="!(selected$ | async)?.includes('prod')" class="second-list-item-expansion" mat-list-item routerLink="/programs/apitoolkit" routerLinkActive="item-active">API Toolkit</a>
            </mat-nav-list>
          </mat-expansion-panel>
        </mat-nav-list>

        <mat-nav-list [disableRipple]="true">
          <mat-expansion-panel [class.mat-elevation-z0]="true" displayMode="flat" hideToggle="true">
            <mat-expansion-panel-header> Cards </mat-expansion-panel-header>
            <mat-nav-list [disableRipple]="true" class="mat-nav-list-expansion-item">
              <a class="second-list-item-expansion" mat-list-item routerLink="/cards/documents" routerLinkActive="item-active">Docs</a>
              <a class="second-list-item-expansion" mat-list-item routerLink="/cards/events" routerLinkActive="item-active">Search</a>
              <a *ngIf="!(selected$ | async)?.includes('prod')" class="second-list-item-expansion" mat-list-item routerLink="/cards/apitoolkit" routerLinkActive="item-active">API Toolkit</a>
            </mat-nav-list>
          </mat-expansion-panel>
        </mat-nav-list>

        <mat-nav-list [disableRipple]="true">
          <mat-expansion-panel [class.mat-elevation-z0]="true" displayMode="flat" hideToggle="true">
            <mat-expansion-panel-header> Payments </mat-expansion-panel-header>
            <mat-nav-list [disableRipple]="true" class="mat-nav-list-expansion-item">
              <a class="second-list-item-expansion" mat-list-item routerLink="/payments/documents" routerLinkActive="item-active">Docs</a>
              <a class="second-list-item-expansion" mat-list-item routerLink="/payments/events" routerLinkActive="item-active">Search</a>
              <a *ngIf="!(selected$ | async)?.includes('prod')" class="second-list-item-expansion" mat-list-item routerLink="/payments/apitoolkit" routerLinkActive="item-active">API Toolkit</a>
            </mat-nav-list>
          </mat-expansion-panel>
        </mat-nav-list>

        <mat-nav-list [disableRipple]="true">
          <mat-expansion-panel [class.mat-elevation-z0]="true" displayMode="flat" hideToggle="true">
            <mat-expansion-panel-header> Ledger </mat-expansion-panel-header>
            <mat-nav-list [disableRipple]="true" class="mat-nav-list-expansion-item">
              <a class="second-list-item-expansion" mat-list-item routerLink="/ledger/documents" routerLinkActive="item-active">Docs</a>
              <a class="second-list-item-expansion" mat-list-item routerLink="/ledger/events" routerLinkActive="item-active">Search</a>
              <a *ngIf="!(selected$ | async)?.includes('prod')" class="second-list-item-expansion" mat-list-item routerLink="/ledger/apitoolkit" routerLinkActive="item-active">API Toolkit</a>
            </mat-nav-list>
          </mat-expansion-panel>
        </mat-nav-list>

        <mat-nav-list [disableRipple]="true">
          <mat-expansion-panel [class.mat-elevation-z0]="true" displayMode="flat" hideToggle="true">
            <mat-expansion-panel-header> Batch </mat-expansion-panel-header>
            <mat-nav-list [disableRipple]="true" class="mat-nav-list-expansion-item">
              <a class="second-list-item-expansion" mat-list-item routerLink="/batch/documents" routerLinkActive="item-active">Docs</a>
              <a *ngIf="!(selected$ | async)?.includes('prod')" class="second-list-item-expansion" mat-list-item routerLink="/batch/apitoolkit" routerLinkActive="item-active">API Toolkit</a>
            </mat-nav-list>
          </mat-expansion-panel>
        </mat-nav-list>

        <mat-nav-list [disableRipple]="true">
          <mat-expansion-panel [class.mat-elevation-z0]="true" displayMode="flat" hideToggle="true">
            <mat-expansion-panel-header> Simulator </mat-expansion-panel-header>
            <mat-nav-list [disableRipple]="true" class="mat-nav-list-expansion-item">
              <a class="second-list-item-expansion" mat-list-item routerLink="/simulator/documents" routerLinkActive="item-active">Docs</a>
              <a *ngIf="!(selected$ | async)?.includes('prod')" class="second-list-item-expansion" mat-list-item routerLink="/simulator/virtual-terminal" routerLinkActive="item-active">Virtual Terminal</a>
              <a *ngIf="!(selected$ | async)?.includes('prod')" class="second-list-item-expansion" mat-list-item routerLink="/simulator/apitoolkit" routerLinkActive="item-active">API Toolkit</a>
            </mat-nav-list>
          </mat-expansion-panel>
        </mat-nav-list>

    </div>

    <mat-nav-list [disableRipple]="true">
      <div>
        <mat-list-item routerLink="/wallet/view" routerLinkActive="item-active">
          Wallet
        </mat-list-item>
      </div>
      <mat-list-item routerLink="/api-keys" routerLinkActive="item-active">
        Api Keys
      </mat-list-item>
      <mat-list-item routerLink="/encryption-utilities" routerLinkActive="item-active">
        Encryption Utilities
      </mat-list-item>
    </mat-nav-list>

    <div mat-subheader>Notification Services</div>
    <mat-nav-list [disableRipple]="true">
      <mat-list-item routerLink="/event-stream" routerLinkActive="item-active">
        Event Stream
      </mat-list-item>
      <mat-list-item routerLink="/web-hook" routerLinkActive="item-active">
        Web Hooks
      </mat-list-item>
    </mat-nav-list>


    <div mat-subheader>Operations</div>
    <mat-nav-list [disableRipple]="true">
      <mat-list-item routerLink="/reports" routerLinkActive="item-active">
        Reports
      </mat-list-item>
    </mat-nav-list>

    <div class="footer">
      <div>
        <div>
          <p class="internal-use-message">FOR INTERNAL USE ONLY. Unauthorized Use/Access Prohibited.</p>
        </div>
        <div class="footer-note">© Copyright 2022 Xformative.
          <br>All rights reserved.
        </div>
        <div *ngIf="!(selected$ | async)?.includes('prod')">
          <p class="app-version">dops version: {{appVersion}}</p>
        </div>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div fxFlex="1 1 100%">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
