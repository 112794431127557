// app/modules/developers/developer.service.ts

import { Injectable } from '@angular/core';
import { v1 as ApiDopsV1 } from '@xform/api-dops';
import { Observable } from 'rxjs';
import { HttpParams, XPSMainHttpService } from '../../shared/services/xps-main-http.service';

@Injectable({
  providedIn: 'root',
})
export class DeveloperService {
  constructor(
    private http: XPSMainHttpService,
  ) {}

  getDeveloper(developerId: string): Observable<ApiDopsV1.DeveloperView> {
    return this.http.get<ApiDopsV1.DeveloperView>(`/api-dops/v1/view/developer/${developerId}`);
  }

  getDeveloperList(lastEvaluatedKey?: string): Observable<ApiDopsV1.DeveloperList> {
    const params = new HttpParams();
    if (typeof lastEvaluatedKey !== 'undefined') {
      params.set('startKey', lastEvaluatedKey);
    }
    return this.http.get<ApiDopsV1.DeveloperList>('/api-dops/v1/view/developer', { params });
  }

  cancelDeveloper(cancelDeveloperEventId: string, cancelDeveloper: ApiDopsV1.CancelDeveloperEvent): Observable<void> {
    return this.http.put<void>(`/api-dops/v1/event/canceldeveloper/${cancelDeveloperEventId}`, {json: cancelDeveloper});
  }

  reactivateDeveloper(reactivateDeveloperEventId: string, reactivateDeveloper: ApiDopsV1.ReactivateDeveloperEvent): Observable<void> {
    return this.http.put<void>(`/api-dops/v1/event/reactivatedeveloper/${reactivateDeveloperEventId}`, {json: reactivateDeveloper});
  }

  modifyDeveloper(modifyDeveloperEventId: string, modifyDeveloper: ApiDopsV1.ModifyDeveloperEvent): Observable<void> {
    return this.http.put<void>(`/api-dops/v1/event/modifydeveloper/${modifyDeveloperEventId}`, {json: modifyDeveloper});
  }

  newDeveloper(newDeveloperEventId: string, newDeveloper: ApiDopsV1.NewDeveloperEvent): Observable<void> {
    return this.http.put<void>(`/api-dops/v1/event/newdeveloper/${newDeveloperEventId}`, {json: newDeveloper});
  }
}
