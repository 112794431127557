// app/modules/core-services/cards/state/card.actions.ts

/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-classes-per-file */

import { createAction, props } from '@ngrx/store';
import { v1 as ApiDops } from '@xform/api-dops';
import { SearchParams } from '../../../shared/services/search/search';

export enum CardActionTypes {
    ClearState = '[Card] Clear State',
    ClearCardListWithCriteria = '[Card] Clear Card List With Criteria',
    CardError = '[Card] Get Card Error',
    ClearCardError = '[Card] Clear Card Error',
    GetCardListWithCriteria = '[Card] Get Card List With Criteria',
    GetCardListWithCriteriaSuccess = '[Card] Get Card List With Criteria Success',
}

export const clearState = createAction(CardActionTypes.ClearState);
export const clearCardListWithCriteria = createAction(CardActionTypes.ClearCardListWithCriteria);
export const cardError = createAction(CardActionTypes.CardError, props<{error: ApiDops.Error}>());
export const clearCardError = createAction(CardActionTypes.ClearCardError);
export const getCardListWithCriteria = createAction(CardActionTypes.GetCardListWithCriteria, props<Partial<SearchParams>>());
export const getCardListWithCriteriaSuccess = createAction(CardActionTypes.GetCardListWithCriteriaSuccess, props<{cardListWithCriteria: ApiDops.SearchResults}>());
