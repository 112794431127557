// app/modules/dashboard/state/metric-reports.actions.ts

import { v1 as apiReport } from '@xform/api-report';
import { createAction, props } from '@ngrx/store';
import { MetricReportType } from '../../reconciliation/reconciliation.service';

export enum MetricReportActionTypes {
  AccountCreationReportError = '[MetricReport] Get Account Creation Report Error',
  AccountReportError = '[MetricReport] Get Account Report Error',
  CardActionActivityReportError = '[MetricReport] Get Card Action Activity Report Error',
  CardReportError = '[MetricReport] Get Card Report Error',
  ClearAccountCreationReportError = '[MetricReport] Clear Account Creation Report Error',
  ClearAccountReportError = '[MetricReport] Clear Account Report Error',
  ClearCardActionActivityReportError = '[MetricReport] Clear Card Action Activity Error',
  ClearCardReportError = '[MetricReport] Clear Card Report Error',
  ClearOrganizationReportError = '[MetricReport] Clear Organization Creation Error',
  GetAccountCreationReport = '[MetricReport] Get Account Creation Report',
  GetAccountCreationReportSuccess = '[MetricReport] Get Account Creation Report Success',
  GetAccountReport = '[MetricReport] Get Account Report',
  GetAccountReportSuccess = '[MetricReport] Get Account Report Success',
  GetCardActionActivityReport = '[MetricReport] Get Card Action Activity Report',
  GetCardActionActivityReportSuccess = '[MetricReport] Get Card Action Activity Report Success',
  GetCardReport = '[MetricReport] Get Card Report',
  GetCardReportSuccess = '[MetricReport] Get Card Report Success',
  GetOrganizationReport = '[MetricReport] Get Organization Report',
  GetOrganizationReportSuccess = '[MetricReport] Get Organization Report Success',
  OrganizationReportError = '[MetricReport] Get Organization Report Error',
  GetMetricReportSuccess = '[MetricReport] Get Metric Report Success',
  GetMetricReport = '[MetricReport] Get Metric Report',
  MetricReportError = '[MetricReport] Get Metric Report Error',
  ClearMetricReportError = '[MetricReport] Clear Metric Report Error',
}

// Account Report
export const accountReportError = createAction(MetricReportActionTypes.AccountReportError, props<{ error: apiReport.Error }>());
export const getAccountReport = createAction(MetricReportActionTypes.GetAccountReport, props<{ fromDate?: string, toDate?: string }>());
export const getAccountReportSuccess = createAction(MetricReportActionTypes.GetAccountReportSuccess, props<{ report: apiReport.ReportResult }>());
export const clearAccountReportError = createAction(MetricReportActionTypes.ClearAccountReportError);

// Account Creation Report
export const accountCreationReportError = createAction(MetricReportActionTypes.AccountCreationReportError, props<{ error: apiReport.Error }>());
export const getAccountCreationReport = createAction(MetricReportActionTypes.GetAccountCreationReport, props<{ fromDate?: string, toDate?: string }>());
export const getAccountCreationReportSuccess = createAction(MetricReportActionTypes.GetAccountCreationReportSuccess, props<{ report: apiReport.ReportResult }>());
export const clearAccountCreationReportError = createAction(MetricReportActionTypes.ClearAccountCreationReportError);

// Card Report
export const cardReportError = createAction(MetricReportActionTypes.CardReportError, props<{ error: apiReport.Error }>());
export const getCardReport = createAction(MetricReportActionTypes.GetCardReport, props<{ fromDate?: string, toDate?: string }>());
export const getCardReportSuccess = createAction(MetricReportActionTypes.GetCardReportSuccess, props<{ report: apiReport.ReportResult }>());
export const clearCardReportError = createAction(MetricReportActionTypes.ClearCardReportError);

// Card Action Activity Report
export const cardActionActivityReportError = createAction(MetricReportActionTypes.CardActionActivityReportError, props<{ error: apiReport.Error }>());
export const getCardActionActivityReport= createAction(MetricReportActionTypes.GetCardActionActivityReport, props<{ fromDate?: string, toDate?: string }>());
export const getCardActionActivityReportSuccess = createAction(MetricReportActionTypes.GetCardActionActivityReportSuccess, props<{ report: apiReport.ReportResult }>());
export const clearCardActionActivityReportError = createAction(MetricReportActionTypes.ClearCardActionActivityReportError);

// Organization Report
export const organizationReportError = createAction(MetricReportActionTypes.OrganizationReportError, props<{ error: apiReport.Error }>());
export const getOrganizationReport = createAction(MetricReportActionTypes.GetOrganizationReport, props<{ fromDate?: string, toDate?: string }>());
export const getOrganizationReportSuccess = createAction(MetricReportActionTypes.GetOrganizationReportSuccess, props<{ report: apiReport.ReportResult }>());
export const clearOrganizationReportError = createAction(MetricReportActionTypes.ClearOrganizationReportError);

// Metric Report
export const metricReportError = createAction(MetricReportActionTypes.MetricReportError, props<{ error: apiReport.Error }>());
export const getMetricReport = createAction(MetricReportActionTypes.GetMetricReport, props<{ metricType?: MetricReportType, fromDate?: string, toDate?: string }>());
export const getMetricReportSuccess = createAction(MetricReportActionTypes.GetMetricReportSuccess, props<{ report: apiReport.ReportResult }>());
export const clearMetricReportError = createAction(MetricReportActionTypes.ClearMetricReportError);

