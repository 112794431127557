// app/store/hydration.reducer.ts

// hydration.reducer.ts
import { Action, ActionReducer } from '@ngrx/store';
import * as hydrationActions from './store.actions';
import { RootState } from '.';

function isHydrateSuccess(
  action: Action,
): action is ReturnType<typeof hydrationActions.hydrateSuccess> {
  return action.type === hydrationActions.hydrateSuccess.type;
}

export const hydrationMetaReducer = (
  reducer: ActionReducer<RootState>,
): ActionReducer<RootState> => (state, action) => {
  if (isHydrateSuccess(action)) {
    return action.state;
  }
  return reducer(state, action);

};
