// app/modules/core-services/programs/state/program.actions.ts

/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-classes-per-file */

import { createAction, props } from '@ngrx/store';
import { SearchResults } from '@xform/api-dops/dist/api/v1';
import { v1 as ProgramApi } from '@xform/api-program';
import { SearchParams } from '../../../shared/services/search/search';

export enum ProgramActionTypes {
  ClearProgram = '[Program]] Clear Program',
  ClearLookupProgram = '[Program] Clear Lookup Program',
  ClearLookupProgramError = '[Program] Clear Lookup Program Error',
  ClearProgramError = '[Program] Clear Program Error',
  ClearProgramList = '[Program] Clear Program List',
  ClearProgramListWithCriteria = '[Program] Clear Program List With Criteria',
  ClearState = '[Program] Clear State',
  DeleteMerchantOverride = '[Program] Delete Merchant Override',
  DeleteMerchantOverrideSuccess = '[Program] Delete Merchant Override Success',
  GetProgram = '[Program] Get Program',
  GetProgramLookup = '[Program] Get Program Lookup',
  GetLookupProgramError = '[Program] Get Program Lookup Error',
  GetLookupProgramSuccess = '[Program] Get Program Lookup Success',
  GetMerchantOverrideList = '[Program] Get Merchant Override List',
  GetMerchantOverrideListSuccess = '[Program] Get Merchant Override List Success',
  GetLatestProgramList = '[Program] Get Latest Program List',
  GetProgramList = '[Program] Get Program List',
  GetProgramListSuccess = '[Program] Get Program List Success',
  GetProgramListWithCriteria = '[Program] Get Program List With Criteria',
  GetProgramListWithCriteriaSuccess = '[Program] Get Program List With Criteria Success',
  GetProgramSuccess = '[Program] Get Program Success',
  GetParentProgramSuccess = '[Program] Get Parent Program Success',
  GetParentProgram = '[Program] Get Parent Program',
  NewProgram = '[Program] New Program',
  NewProgramSuccess = '[Program] New Program Success',
  ProgramError = '[Program] Get Program Error',
  SetClientId = '[Program] Set Client Id',
  UpdateCardFulfillment = '[Program] Update Card Fulfillment',
  UpdateCardFulfillmentSuccess = '[Program] Update Card Fulfillment Success',
  UpdateRestrictionCountries = '[Program] Update Country Restriction Ids',
  UpdateRestrictionCountriesSuccess = '[Program] Update Country Restriction Ids Success',
  UpdateMerchantRestrictionsIds = '[Program] Update Merchant Restriction Ids',
  UpdateMerchantRestrictionsIdsSuccess = '[Program] Update Merchant Restriction Ids Success',
  UpdateMerchantRestrictionTypes = '[Program] Update Merchant Restriction Types',
  UpdateMerchantRestrictionTypesSuccess = '[Program] Update Merchant Restriction Types Success',
  UpdateProcessorLimits = '[Program] Update Processor Limits',
  UpdateProcessorLimitsSuccess = '[Program] Update Processor Limits Success',
  UpdateProcessorTolerances = '[Program] Update Processor Tolerances',
  UpdateProcessorTolerancesSuccess = '[Program] Update Processor Tolerances Success',
  UpdateDepletionOrder = '[Program] Update Depletion Order',
  UpdateDepletionOrderSuccess = '[Program] Update Depletion Order Success',
  UpdatePriorityUnmatchedReturnDepletionOrder = '[Program] Update Priority Unmatched Return Depletion Order',
  UpdatePriorityUnmatchedReturnDepletionOrderSuccess = '[Program] Update Priority Unmatched Return Depletion Order Success',
  NewCashFeature = '[Program] New Cash Feature',
  NewCashFeatureSuccess = '[Program] New Cash Feature Success',
  NewAdvanceFeature = '[Program] New Advance Feature',
  NewAdvanceFeatureSuccess = '[Program] New Advance Feature Success',
  NewMerchantOverride = '[Program] New Merchant Override',
  NewMerchantOverrideSuccess = '[Program] New Merchant Override Success',
  NewOffering = '[Program] New Program Offering',
  NewOfferingSuccess = '[Program] New Program Offering Success',
  CancelOffering = '[Program] Cancel Offering',
  CancelOfferingSuccess = '[Program] Cancel Offering Success',
  ModifyOffering = '[Program] Modify Offering',
  ModifyOfferingSuccess = '[Program] Modify Offering Success',
  ModifyOfferingManualAuthEligibility = '[Program] Modify Offering Manual Auth eligibility',
  ModifyOfferingManualAuthEligibilitySuccess = '[Program] Modify Offering Manual Auth eligibility Success',
  ModifyOfferingCardAuthEligibility = '[Program] Modify Offering Card Auth eligibility',
  ModifyOfferingCardAuthEligibilitySuccess = '[Program] Modify Offering Card Auth eligibility Success',
  ModifyOfferingRollover = '[Program] Modify Offering Rollover',
  ModifyOfferingRolloverSuccess = '[Program] Modify Offering Rollover Success',
  RemoveOfferingRollover = '[Program] Remove Offering Rollover',
  RemoveOfferingRolloverSuccess = '[Program] Remove Offering Rollover Success',
  SetOfferingGracePeriod = '[Program]  Set Offering Grace Period',
  SetOfferingGracePeriodSuccess = '[Program]  Set Offering Grace Period Success',
  ModifyProgram = '[Program] Modify Program',
  ModifyProgramSuccess = '[Program] Modify Program Success',
}

export const programError = createAction(ProgramActionTypes.ProgramError, props<{ error: ProgramApi.Error }>());
export const clearProgramError = createAction(ProgramActionTypes.ClearProgramError);
export const modifyProgram = createAction(ProgramActionTypes.ModifyProgram, props<{ modifyProgramEventId: string, modifyProgramEvent: ProgramApi.ModifyProgramEvent }>());
export const modifyProgramSuccess = createAction(ProgramActionTypes.ModifyProgramSuccess);
export const clearState = createAction(ProgramActionTypes.ClearState);
export const clearProgramList = createAction(ProgramActionTypes.ClearProgramList);
export const clearProgramListWithCriteria = createAction(ProgramActionTypes.ClearProgramListWithCriteria);
export const clearProgram = createAction(ProgramActionTypes.ClearProgram);
export const clearLookupProgram = createAction(ProgramActionTypes.ClearLookupProgram);
export const clearLookupProgramError = createAction(ProgramActionTypes.ClearLookupProgramError);
export const getProgram = createAction(ProgramActionTypes.GetProgram, props<{ programId: string, at: string }>());
export const getParentProgram = createAction(ProgramActionTypes.GetParentProgram, props<{ parentProgramId: string, at: string }>());
export const getLookupProgram = createAction(ProgramActionTypes.GetProgramLookup, props<{ programLookupId: string, at: string }>());
export const lookupProgramError = createAction(ProgramActionTypes.GetLookupProgramError, props<{ lookupProgramError: ProgramApi.Error }>());
export const getLookupProgramSuccess = createAction(ProgramActionTypes.GetLookupProgramSuccess, props<{ lookupProgram: ProgramApi.ProgramView }>());
export const deleteMerchantOverride = createAction(ProgramActionTypes.DeleteMerchantOverride, props<{ programId: string, overrideId: string }>());
export const deleteMerchantOverrideSuccess = createAction(ProgramActionTypes.DeleteMerchantOverrideSuccess);

// TODO change type
export const getMerchantOverrideList = createAction(ProgramActionTypes.GetMerchantOverrideList, props<{ programId: string, includesAncestors: boolean }>());
export const getMerchantOverrideListSuccess = createAction(ProgramActionTypes.GetMerchantOverrideListSuccess, props<{ merchantOverrideList: ProgramApi.OverrideList }>());
export const getProgramSuccess = createAction(ProgramActionTypes.GetProgramSuccess, props<{ program: ProgramApi.ProgramView }>());
export const getParentProgramSuccess = createAction(ProgramActionTypes.GetParentProgramSuccess, props<{ parentProgram: ProgramApi.ProgramView }>());
export const getLatestProgramList = createAction(ProgramActionTypes.GetLatestProgramList);
export const getProgramList = createAction(ProgramActionTypes.GetProgramList, props<{ lastEvaluatedKey?: string }>());
export const getProgramListSuccess = createAction(ProgramActionTypes.GetProgramListSuccess, props<{ programList: ProgramApi.ProgramList, lastEvaluatedKey: string }>());
export const getProgramListWithCriteria = createAction(ProgramActionTypes.GetProgramListWithCriteria, props<Partial<SearchParams>>());
export const getProgramListWithCriteriaSuccess = createAction(ProgramActionTypes.GetProgramListWithCriteriaSuccess, props<{ programListWithCriteria: SearchResults }>());
export const newMerchantOverride = createAction(ProgramActionTypes.NewMerchantOverride, props<{ newMerchantOverrideEvent: ProgramApi.MerchantOverrideEvent, overrideId: string }>());
export const newMerchantOverrideSuccess = createAction(ProgramActionTypes.NewMerchantOverrideSuccess);
export const newProgramSuccess = createAction(ProgramActionTypes.NewProgramSuccess);
export const updateCardFulfillment = createAction(ProgramActionTypes.UpdateCardFulfillment, props<{ setCardFulfillmentEventId: string, setCardFulfillmentEvent: ProgramApi.SetCardFulfillmentEvent }>());
export const updateCardFulfillmentSuccess = createAction(ProgramActionTypes.UpdateCardFulfillmentSuccess);
export const updateRestrictionCountries = createAction(ProgramActionTypes.UpdateRestrictionCountries, props<{ setProcessorRestrictCountriesEventId: string, setProcessorRestrictCountriesEvent: ProgramApi.SetProcessorRestrictCountriesEvent }>());
export const updateRestrictionCountriesSuccess = createAction(ProgramActionTypes.UpdateRestrictionCountriesSuccess);
export const updateMerchantRestrictionsIds = createAction(ProgramActionTypes.UpdateMerchantRestrictionsIds, props<{ setProcessorRestrictMerchantIdsEventId: string, setProcessorRestrictMerchantIdsEvent: ProgramApi.SetProcessorRestrictMerchantIdsEvent }>());
export const updateMerchantRestrictionsIdsSuccess = createAction(ProgramActionTypes.UpdateMerchantRestrictionsIdsSuccess);
export const updateMerchantRestrictionTypes = createAction(ProgramActionTypes.UpdateMerchantRestrictionTypes, props<{ setProcessorRestrictMerchantTypesEventId: string, setProcessorRestrictMerchantTypesEvent: ProgramApi.SetProcessorRestrictMerchantTypesEvent }>());
export const updateMerchantRestrictionTypesSuccess = createAction(ProgramActionTypes.UpdateMerchantRestrictionTypesSuccess);
export const updateProcessorLimits = createAction(ProgramActionTypes.UpdateProcessorLimits, props<{ setProcessorLimitsEventId: string, setProcessorLimitsEvent: ProgramApi.SetProcessorLimitsEvent }>());
export const updateProcessorLimitsSuccess = createAction(ProgramActionTypes.UpdateProcessorLimitsSuccess);
export const updateProcessorTolerances = createAction(ProgramActionTypes.UpdateProcessorTolerances, props<{ setProcessorTolerancesEventId: string, setProcessorTolerancesEvent: ProgramApi.SetProcessorTolerancesEvent }>());
export const updateProcessorTolerancesSuccess = createAction(ProgramActionTypes.UpdateProcessorTolerancesSuccess);
export const updateDepletionOrder = createAction(ProgramActionTypes.UpdateDepletionOrder, props<{ setPriorityDepletionOrderEventId: string, setPriorityDepletionOrderEvent: ProgramApi.SetPriorityDepletionOrderEvent }>());
export const updateDepletionOrderSuccess = createAction(ProgramActionTypes.UpdateDepletionOrderSuccess);
export const updatePriorityUnmatchedReturnDepletionOrder = createAction(ProgramActionTypes.UpdatePriorityUnmatchedReturnDepletionOrder, props<{ setPriorityUnmatchedReturnDepletionOrderEventId: string, setPriorityUnmatchedReturnDepletionOrderEvent: ProgramApi.SetPriorityUnmatchedReturnDepletionOrderEvent }>());
export const updatePriorityUnmatchedReturnDepletionOrderSuccess = createAction(ProgramActionTypes.UpdatePriorityUnmatchedReturnDepletionOrderSuccess);
export const cancelOffering = createAction(ProgramActionTypes.CancelOffering, props<{ cancelOfferingEventId: string, cancelOfferingEvent: ProgramApi.CancelOfferingEvent }>());
export const cancelOfferingSuccess = createAction(ProgramActionTypes.CancelOfferingSuccess);

export const newProgram = createAction(ProgramActionTypes.NewProgram,
  props<{
     newProgramEventId: string,
     newProgramEvent: ProgramApi.NewProgramEvent,
     newCashFeatureEventId?: string,
     newCashFeatureEvent?: ProgramApi.NewCashFeatureEvent,
     newAdvanceFeatureEventId?: string,
     newAdvanceFeatureEvent?: ProgramApi.NewAdvanceFeatureEvent,
     lastEvaluatedKey?: string,
    }>());

export const newOffering = createAction(ProgramActionTypes.NewOffering,
  props< {
    newOfferingEventId?: string,
    newOfferingEvent?: ProgramApi.NewOfferingEvent
    setOfferingManualAuthEligibilityId?: string,
    setOfferingManualAuthEligibilityEvent?: ProgramApi.SetOfferingManualAuthEligibilityEvent,
    setOfferingCardAuthEligibilityId?: string,
    setOfferingCardAuthEligibilityEvent?: ProgramApi.SetOfferingCardAuthEligibilityEvent,
    setOfferingRolloverEventId?: string,
    setOfferingRolloverEvent?: ProgramApi.SetOfferingRolloverEvent,
    setOfferingGracePeriodEventId?: string,
    setOfferingGracePeriodEvent?: ProgramApi.SetOfferingGracePeriodEvent,
  } >());

export const newOfferingSuccess = createAction(ProgramActionTypes.NewOfferingSuccess);

export const modifyOffering = createAction(ProgramActionTypes.ModifyOffering, props<{
  modifyOfferingEventId?: string,
  modifyOfferingEvent?: ProgramApi.ModifyOfferingEvent,
  setOfferingManualAuthEligibilityId?: string,
  setOfferingManualAuthEligibilityEvent?: ProgramApi.SetOfferingManualAuthEligibilityEvent,
  setOfferingCardAuthEligibilityId?: string,
  setOfferingCardAuthEligibilityEvent?: ProgramApi.SetOfferingCardAuthEligibilityEvent,
  setOfferingRolloverEventId?: string,
  setOfferingRolloverEvent?: ProgramApi.SetOfferingRolloverEvent,
  removeOfferingRolloverEventId?: string,
  removeOfferingRolloverEvent?: ProgramApi.RemoveOfferingRolloverEvent,
  setOfferingGracePeriodEventId?: string,
  setOfferingGracePeriodEvent?: ProgramApi.SetOfferingGracePeriodEvent,
}>());

export const modifyOfferingSuccess = createAction(ProgramActionTypes.ModifyOfferingSuccess);
