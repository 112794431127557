// app/shared/directives/directives.module.ts

import { NgModule } from '@angular/core';
import { ClaimsDisableDirective } from './claim-disable.directive';
import { IfAccessDirective } from './if-access.directive';
import { ZeroDollarCheckDirective } from './zeroDollar.directive';

@NgModule({
  imports: [],
  declarations: [ClaimsDisableDirective, IfAccessDirective, ZeroDollarCheckDirective],
  exports: [ClaimsDisableDirective, IfAccessDirective, ZeroDollarCheckDirective],
})
export class DirectivesModule { }
