// app/modules/notification/event-stream/state/event-stream.reducer.ts

import { createReducer, on } from '@ngrx/store';
import { v1 as ApiDops } from '@xform/api-dops';
import * as eventStreamSettingsAction from './event-stream.actions';

export interface State {
  eventStreamSettingsId?: string;
  eventStreamSettings?: ApiDops.EventStreamSettingsView;
  error?: ApiDops.Error;
  loading: boolean;
}

const initialState: State = {
  loading: false,
};

export const reducers = createReducer(
  initialState,
  on(eventStreamSettingsAction.eventStreamSettingsError, (state, {error}) => ({...state, error, loading: false})),
  on(eventStreamSettingsAction.clearEventStreamSettingsError, (state) => ({...state, error: null})),
  on(eventStreamSettingsAction.getEventStreamSettings, (state, {eventStreamSettingsId}) => ({
    ...state,
    eventStreamSettingsId,
    error: null,
    loading: true,
  })),
  on(eventStreamSettingsAction.getEventStreamSettingsSuccess, (state, {eventStreamSettings}) => ({
    ...state,
    eventStreamSettings,
    error: null,
    loading: false,
  })),
);
