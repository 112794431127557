// aws-config.ts

// ignore next line - at present lastest aws-amplify-angular depends on deprecated version/types in aws-amplify
/* eslint-disable import/no-deprecated, import/no-extraneous-dependencies */
import { Amplify as amplify } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import { environment } from './environments/environment';

export const configureAWS = (): void => {
  amplify.configure({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Auth: {

      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: environment.authIdentityPoolId,

      // REQUIRED - Amazon Cognito Region
      region: environment.authRegion,

      // OPTIONAL - Amazon Cognito Federated Identity Pool Region
      // Required only if it's different from Amazon Cognito Region
      // identityPoolRegion: 'XX-XXXX-X',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: environment.authUserPoolId,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: environment.authUserPoolWebClientId,

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: true,

      // OPTIONAL - Configuration for cookie storage
      // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
      // cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      //    domain: '.yourdomain.com',
      // OPTIONAL - Cookie path
      //    path: '/',
      // OPTIONAL - Cookie expiration in days
      //    expires: 365,
      // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      //    sameSite: "strict" | "lax",
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      //    secure: true
      // },

      // OPTIONAL - customized storage object
      // storage: MyStorage,

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: environment.authenticationFlowType,

      // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
      // clientMetadata: { myCustomKey: 'myCustomValue' },

      // OPTIONAL - Hosted UI configuration
      oauth: {
        domain: environment.oauthDomain,
        scope: environment.oauthScope,
        redirectSignIn: environment.oauthRedirectSignIn,
        redirectSignOut: environment.oauthRedirectSignOut,
        responseType: environment.oauthResponseType,
      },
    },
  });
};

// You can get the current config object
const currentConfig = Auth.configure();

/* eslint-enable */
