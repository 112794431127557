// app/app.component.ts

import { Component, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements   OnDestroy {
  title = 'xformative-dops';

  private subscriptions = new Subscription();

  eventRoute$ = this.router.events.pipe(
    filter(event => event instanceof NavigationStart),
  );

  constructor(private router: Router) {
    const routeSub = this.eventRoute$.subscribe();
    this.subscriptions.add(routeSub);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
