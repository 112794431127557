// app/shared/directives/if-access.directive.ts

/* eslint-disable @typescript-eslint/naming-convention */

import { Directive, ElementRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectAccessClaim } from '@src/app/core/auth/auth.selectors';
import { map } from 'rxjs/internal/operators/map';
import { accessMap, RoleBasedPermission } from './access.map';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[hasPermission]',
})
export class IfAccessDirective {

  componentElement: string;

  access$ = this.store.select(selectAccessClaim).pipe(
    map(claim => claim),
  );

  currentUser: string;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store: Store,
  ) {}

  // eslint-disable-next-line accessor-pairs
  @Input()
  set hasPermission(val: RoleBasedPermission) {
    this.componentElement = val;
    this.access$.subscribe(access => {
      this.updateView(access);
    });
  }

  private updateView(currentUser: string) {
    if (this.checkPermission(currentUser)) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkPermission(currentUser: string) {
    let hasPermission = false;

    if (accessMap[this.componentElement]?.includes(currentUser)) {
      hasPermission = true;
    }

    return hasPermission;
  }
}
