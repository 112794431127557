// app/modules/shared/services/search/search.ts

import { v1 as ApiDopsV1 } from '@xform/api-dops';
import { Observable } from 'rxjs';
import { HttpParams, XPSHttpService } from '../../../../shared/services/xps-http.service';

export enum SearchableServiceId {
  Batch = 'api-batch',
  Card = 'api-card',
  Ledger = 'api-ledger',
  Organization = 'api-organization',
  Payment = 'api-payment',
  Program = 'api-program',
  Simulator = 'api-simulator',
};

export enum ServiceId {
  Batch = 'api-batch',
  Card = 'api-card',
  Dops = 'api-dops',
  Ledger = 'api-ledger',
  Fops = 'api-fops',
  Organization = 'api-organization',
  Payment = 'api-payment',
  Program = 'api-program',
  Report = 'api-report',
  Simulator = 'api-simulator',
};

export interface SearchParams {
    searchEndDateTime?: string,
    searchTerm: string,
    serviceId: string,
}

export function getListWithCriteria(http: XPSHttpService, criteria: SearchParams): Observable<ApiDopsV1.SearchResults> {
  if (!Object.values(SearchableServiceId).includes(criteria.serviceId as SearchableServiceId)) {
    throw new Error(`${criteria.serviceId} is not a searchable service!`);
  }

  if (!criteria.searchEndDateTime) {
    delete criteria.searchEndDateTime;
  }

  const params = new HttpParams();
  Object.keys(criteria).forEach(key => {
    params.set(key, criteria[key] as string);
  });

  return http.get<ApiDopsV1.SearchResults>('/api-dops/v1/view/search', { params });
}
