// app/core/auth/fops.service.ts

import { Injectable } from '@angular/core';
import { v1 as ApiFops } from '@xform/api-fops';
import { Observable } from 'rxjs';
import { XPSMainHttpService } from '../../shared/services/xps-main-http.service';

@Injectable({
  providedIn: 'root',
})
export class FopsService {

  constructor(
    private http: XPSMainHttpService,
  ) {}

  getOpsUser(opsUserId: string): Observable<ApiFops.OpsUserView> {
    const at = encodeURIComponent(new Date().toISOString());
    return this.http.get<ApiFops.OpsUserView>(`/api-fops/v1/view/opsuser/${opsUserId}?at=${at}`);
  }

}