// app/shared/directives/access.map.ts

// only roles with access
export enum AccessRoles {
  DevAdmin = 'dev-admin',
  DevGeneral = 'dev-general',
  DevOwner = 'dev-owner',
  DevSupport = 'dev-support',
  OpsDev = 'ops-dev',
}

// Edit = read-write, View = read-only
export enum RoleBasedPermission {
  AddMerchantOverride = 'AddMerchantOverride',
  EditAccessKey = 'EditAccessKey',
  EditDeveloper = 'EditDeveloper',
  EditProgram = 'EditProgram',
  EditWebHook = 'EditWebHook',
  ViewBureau= 'ViewBureau',
  ViewDeveloper= 'ViewDeveloper',
  ViewInvoice = 'ViewInvoice',
  ViewWebHook = 'ViewWebHook',
}

export const accessMap = {
  [RoleBasedPermission.AddMerchantOverride]: [AccessRoles.DevAdmin, AccessRoles.DevGeneral, AccessRoles.DevOwner],
  [RoleBasedPermission.EditAccessKey]: [AccessRoles.DevAdmin, AccessRoles.DevOwner],
  [RoleBasedPermission.EditDeveloper]: [AccessRoles.DevAdmin, AccessRoles.DevOwner],
  [RoleBasedPermission.EditProgram]: [AccessRoles.DevAdmin, AccessRoles.DevGeneral, AccessRoles.DevOwner],
  [RoleBasedPermission.EditWebHook]: [AccessRoles.DevAdmin, AccessRoles.DevGeneral, AccessRoles.DevOwner],
  [RoleBasedPermission.ViewBureau]: [AccessRoles.DevAdmin, AccessRoles.DevOwner, AccessRoles.OpsDev],
  [RoleBasedPermission.ViewDeveloper]: [AccessRoles.DevGeneral, AccessRoles.DevSupport, AccessRoles.OpsDev],
  [RoleBasedPermission.ViewInvoice]: [AccessRoles.DevOwner, AccessRoles.OpsDev],
  [RoleBasedPermission.ViewWebHook]: [AccessRoles.DevSupport, AccessRoles.OpsDev],
};
