// app/modules/core-services/simulator/simulator.reducer.ts

import { Action, createReducer, on } from '@ngrx/store';
import { v1 as ApiLedger } from '@xform/api-ledger';
import { v1 as ApiSimulator } from '@xform/api-simulator';
import { v1 as HistoryV1 } from '@xform/history';
import { v1 as ApiDops } from '@xform/api-dops';
import * as simulatorActionTypes from './simulator.actions';

export interface State {
  accountBalances?: ApiLedger.BalancesWithAccountInfo[];
  at?: string;
  authorizationEventId?: string;
  authorizationMessage?: ApiSimulator.AuthorizationMessage;
  clearingEventId?: string;
  clearingMessage?: ApiSimulator.ClearingMessage;
  error?: ApiSimulator.Error;
  groupId?: string;
  ledgerListWithCriteria?: ApiDops.SearchResults;
  loading: boolean;
  responseMessage?: ApiSimulator.MessageResponse;
  returnMessage?: ApiSimulator.Message;
  returnMessageEventId?: string;
  reversalMessage?: ApiSimulator.ReversalMessage;
  reversalMessageId?: string;
  transactionPreviousTokenStack: string[];
  transactions?: HistoryV1.PaginatedTransactions;
}

const initialState: State = {
  loading: false,
  transactionPreviousTokenStack: [],
};

export const reducers = createReducer(
  initialState,
  on(simulatorActionTypes.newAuthorization, (state, { authorizationEventId, authorizationMessage }) => ({ ...state, authorizationEventId, authorizationMessage, error: null, loading: true })),
  on(simulatorActionTypes.newClearing, (state, { clearingEventId, clearingMessage }) => ({ ...state, clearingEventId, clearingMessage, error: null, loading: true })),
  on(simulatorActionTypes.newReversal, (state, { reversalMessageId, reversalMessage }) => ({ ...state, reversalMessageId, reversalMessage, error: null, loading: true })),
  on(simulatorActionTypes.newReturn, (state, { returnMessageEventId, returnMessage }) => ({ ...state, returnMessageEventId, returnMessage, error: null, loading: true })),
  on(simulatorActionTypes.handleResponse, (state, { responseMessage }) => ({ ...state, responseMessage, error: null, loading: false })),
  on(simulatorActionTypes.clearResponse, (state) => ({ ...state, responseMessage: null })),
  on(simulatorActionTypes.handleError, (state, { error }) => ({ ...state, error, loading: false })),
  on(simulatorActionTypes.clearError, (state) => ({ ...state, error: null, loading: false})),
);
