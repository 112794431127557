// app/modules/dashboard/state/metric-reports.reducer.ts

import { createReducer, on } from '@ngrx/store';
import { v1 as ApiReport } from '@xform/api-report';
import * as metricReportActions from './metric-reports.actions';

export interface Loaded {
  loading: boolean;
  loaded: boolean;
}

export interface ReportFields {
  report?: ApiReport.ReportResult;
  error?: ApiReport.Error;
  loading?: Loaded
}
export interface State {
  accountReport?: ReportFields
  actionCreationReport?: ReportFields;
  cardReport?: ReportFields;
  cardActionActivityReport?: ReportFields;
  organizationReport?: ReportFields;
  metricReport?: ReportFields;
  metricType?: string;
}

const initialState: State = {
  accountReport: { loading: { loaded: false, loading: false } },
  actionCreationReport: { loading: { loaded: false, loading: false } },
  cardReport: { loading: { loaded: false, loading: false } },
  cardActionActivityReport: { loading: { loaded: false, loading: false } },
  organizationReport: { loading: { loaded: false, loading: false } },
  metricReport: { loading: { loaded: false, loading: false } },
};

export const reducers = createReducer(
  initialState,
  on(metricReportActions.accountReportError, (state, { error }) => ({ ...state, accountReport: { ...state.accountReport, error } })),
  on(metricReportActions.clearAccountReportError, (state) => ({ ...state, accountReport: { ...state.accountReport, error: null } })),
  on(metricReportActions.getAccountReport, (state) => ({ ...state, accountReport: { ...state.accountReport, error: null, loading: { ...state.accountReport.loading, loading: true } } })),
  on(metricReportActions.getAccountReportSuccess, (state, { report }) => ({
    ...state,
    accountReport: {
      ...state.accountReport,
      error: null,
      report,
      loading: {
        ...state.accountReport.loading,
        loading: false,
        loaded: true,
      },
    },
  })),
  on(metricReportActions.accountCreationReportError, (state, { error }) => ({ ...state, actionCreationReport: { ...state.actionCreationReport, error } })),
  on(metricReportActions.clearAccountCreationReportError, (state) => ({ ...state, actionCreationReport: { ...state.actionCreationReport, error: null } })),
  on(metricReportActions.getAccountCreationReport, (state) => ({ ...state, actionCreationReport: { ...state.actionCreationReport, error: null, loading: { ...state.actionCreationReport.loading, loading: true } } })),
  on(metricReportActions.getAccountCreationReportSuccess, (state, { report }) => ({
    ...state,
    actionCreationReport: {
      ...state.actionCreationReport,
      error: null,
      report,
      loading: {
        ...state.actionCreationReport.loading,
        loading: false,
        loaded: true,
      },
    },
  })),
  on(metricReportActions.cardReportError, (state, { error }) => ({ ...state, cardReport: { ...state.cardReport, error } })),
  on(metricReportActions.clearCardReportError, (state) => ({ ...state, cardReport: { ...state.cardReport, error: null } })),
  on(metricReportActions.getCardReport, (state) => ({ ...state, cardReport: { ...state.cardReport, error: null, loading: { ...state.cardReport.loading, loading: true } } })),
  on(metricReportActions.getCardReportSuccess, (state, { report }) => ({
    ...state,
    cardReport: {
      ...state.cardReport,
      error: null,
      report,
      loading: {
        ...state.cardReport.loading,
        loading: false,
        loaded: true,
      },
    },
  })),
  on(metricReportActions.cardActionActivityReportError, (state, { error }) => ({ ...state, cardActionActivityReport: { ...state.cardReport, error } })),
  on(metricReportActions.clearCardActionActivityReportError, (state) => ({ ...state, cardActionActivityReport: { ...state.cardActionActivityReport, error: null } })),
  on(metricReportActions.getCardActionActivityReport, (state) => ({ ...state, cardActionActivityReport: { ...state.cardActionActivityReport, error: null, loading: { ...state.cardActionActivityReport.loading, loading: true } } })),
  on(metricReportActions.getCardActionActivityReportSuccess, (state, { report }) => ({
    ...state,
    cardActionActivityReport: {
      ...state.cardActionActivityReport,
      error: null,
      report,
      loading: {
        ...state.cardActionActivityReport.loading,
        loading: false,
        loaded: true,
      },
    },
  })),
  on(metricReportActions.organizationReportError, (state, { error }) => ({ ...state, organizationReport: { ...state.organizationReport, error } })),
  on(metricReportActions.clearOrganizationReportError, (state) => ({ ...state, organizationReport: { ...state.organizationReport, error: null } })),
  on(metricReportActions.getOrganizationReport, (state) => ({ ...state, organizationReport: { ...state.organizationReport, error: null, loading: { ...state.organizationReport.loading, loading: true } } })),
  on(metricReportActions.getOrganizationReportSuccess, (state, { report }) => ({
    ...state,
    organizationReport: {
      ...state.organizationReport,
      error: null,
      report,
      loading: {
        ...state.organizationReport.loading,
        loading: false,
        loaded: true,
      },
    },
  })),
  on(metricReportActions.metricReportError, (state, { error }) => ({ ...state, metricReport: { ...state.metricReport, error } })),
  on(metricReportActions.clearMetricReportError, (state) => ({ ...state, metricReport: { ...state.metricReport, error: null } })),
  on(metricReportActions.getMetricReport, (state, {metricType}) => ({ ...state, metricType, metricReport: { ...state.metricReport, error: null, loading: { ...state.metricReport.loading, loading: true } } })),
  on(metricReportActions.getMetricReportSuccess, (state, { report }) => ({
    ...state,
    metricReport: {
      ...state.metricReport,
      error: null,
      report,
      loading: {
        ...state.metricReport.loading,
        loading: false,
        loaded: true,
      },
    },
  })),
);
